const requiredRule = (name) => ({
	required: true,
	message: `${name} is required`,
});

const onlyNumbersRule = () => ({
	validator(_, value) {
		if (Number.isNaN(+value))
			return Promise.reject(new Error('Only Numbers are valid'));

		return Promise.resolve();
	},
});

export { requiredRule, onlyNumbersRule };
