import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { getUser } from 'redux/features/auth';

function RequireAuth({ requiresAdmin }) {
	const user = useSelector(getUser);
	const location = useLocation();

	if (user?.isAdmin) {
		return <Outlet />;
	}
	if (user) {
		if (requiresAdmin) {
			return (
				<Navigate to='/unathorized' state={{ from: location }} replace />
			);
		}
		return <Outlet />;
	}
	return <Navigate to='/login' state={{ from: location }} replace />;
}

RequireAuth.defaultProps = {
	requiresAdmin: false,
};

RequireAuth.propTypes = {
	requiresAdmin: PropTypes.bool,
};

export default RequireAuth;
