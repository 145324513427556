import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
	Table,
	Button,
	PageHeader,
	message,
	Typography,
	Popconfirm,
} from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getUser } from 'redux/features/auth';
import { onRemove } from 'utils/firebase/storage';
import useAxiosPrivate from 'hooks/use-axios-private';
import useURLQuery from 'hooks/use-url-query';
import useWindowDimensions from 'hooks/use-window-dimensions';
import useAntdTableSearch from 'hooks/use-antd-table-search';
import './styles.scss';

export default function Poxes() {
	const navigate = useNavigate();
	const location = useLocation();
	const user = useSelector(getUser);
	const axiosPrivate = useAxiosPrivate();
	const { height, width } = useWindowDimensions();
	const [pageSize, setPageSize] = useState(8);
	const queryClient = useQueryClient();
	const url = useMemo(
		() => (location.pathname === '/poxes/owned' ? '&ownership=true' : ''),
		[location]
	);
	const getColumnSearchProps = useAntdTableSearch();
	const URLquery = useURLQuery();
	const currentPage = URLquery.get('page') ? +URLquery.get('page') : 1;

	const { isLoading, data } = useQuery(
		['poxes', { userid: user.uid, url }],
		async () => {
			const res = await axiosPrivate.get(
				`/poxes?publishedBy=${user.uid}${url}`
			);
			return res.data?.poxes;
		}
	);

	// delete pox
	const deletePox = useMutation({
		mutationFn: async (id) => {
			data.find((e) => e.id === id)?.images.forEach((img) => onRemove(img));
			await axiosPrivate.delete(`/poxes/${id}`);
		},
		onSuccess: () => {
			message.success('Pox deleted');
			queryClient.invalidateQueries(['poxes', { userid: user.uid, url }]);
		},
	});

	useEffect(() => {
		if (height > 800) setPageSize(8);
		else if (height > 700) setPageSize(7);
		else setPageSize(5);
	}, [height]);

	const query = useURLQuery();
	const statusQuery = query.get('status');
	const columns = useMemo(() => {
		const main = [
			{
				title: 'Pox Id',
				dataIndex: 'id',
				key: 'id',
				defaultSortOrder: 'descend',
				sorter: (a, b) => +a.id - +b.id,
				render: (value) => <Link to={`/poxes/${value}`}>{value}</Link>,
			},
			{
				title: 'Pox Name',
				dataIndex: 'name',
				key: 'name',
				...getColumnSearchProps('name'),
			},
			{
				title: 'Type',
				dataIndex: 'type',
				filters: [
					{
						text: 'Plot',
						value: 'Plot',
					},
					{
						text: 'Flat',
						value: 'Flat',
					},
					{
						text: 'Estab',
						value: 'Estab',
					},
				],
				onFilter: (value, record) => record.type.indexOf(value) === 0,
			},
			{
				title: 'Status',
				dataIndex: 'status',
				filters: [
					{
						text: 'Active',
						value: 'active',
					},
					{
						text: 'Inactive',
						value: 'inactive',
					},
				],
				onFilter: (value, record) => record.status.indexOf(value) === 0,
				defaultFilteredValue: statusQuery ? [statusQuery] : null,
				render: (text) => (
					<Typography.Text
						strong
						className={`status-${text}`}
						style={{ textTransform: 'uppercase', fontSize: '0.9em' }}
					>
						{text}
					</Typography.Text>
				),
			},
		];
		const action = {
			title: '',
			key: 'action',
			render: (_text, record) => (
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<Popconfirm
						title='Permanently Delete this Pox?'
						description='Are you sure you want to delete this pox? Cannot be reversed!'
						okText='Yes'
						cancelText='No'
						onConfirm={() => deletePox.mutate(record.id)}
					>
						<Button
							type='danger'
							className='del-button'
							icon={<DeleteOutlined />}
							loading={deletePox.isLoading}
						/>
					</Popconfirm>
				</div>
			),
		};

		if (width > 578) {
			return [
				...main.slice(0, 3),
				{
					title: 'Date Published',
					dataIndex: 'createdAt',
					key: 'createdAt',
					...getColumnSearchProps('createdAt'),
					defaultSortOrder: 'descend',
					sorter: (a, b) =>
						new Date(a.createdAt).getTime() -
						new Date(b.createdAt).getTime(),
					render: (text) =>
						new Date(text).toLocaleDateString('en-uk', {
							year: 'numeric',
							month: 'short',
							day: 'numeric',
						}),
				},
				...main.slice(3, main.length),
				action,
			];
		}
		return main;
	}, [statusQuery, width]);

	return (
		<div className='poxes-page'>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<PageHeader
					className='site-page-header'
					onBack={() => navigate(-1)}
					title={
						location.pathname === '/poxes/owned'
							? 'My Poxes'
							: 'All Poxes'
					}
				/>
				{location.pathname === '/poxes' && (
					<Button
						type='primary'
						className='create-pox-btn'
						style={{ marginRight: 20 }}
						icon={<PlusOutlined />}
						onClick={() => navigate('/poxes/new')}
					>
						Add Pox
					</Button>
				)}
			</div>

			<div className='poxes-content-wrapper'>
				<Table
					rowKey='id'
					columns={columns}
					dataSource={data}
					loading={isLoading}
					pagination={{
						pageSize,
						current: currentPage,
						onChange: (e) => navigate(`?page=${e}`),
					}}
				/>
			</div>
		</div>
	);
}
