import {
	LineChart,
	ResponsiveContainer,
	Legend,
	Tooltip,
	Line,
	YAxis,
	CartesianGrid,
} from 'recharts';

const data = [
	{ Flat: 11, Plot: 120, Estab: 130 },
	{ Flat: 9, Plot: 130, Estab: 150 },
	{ Flat: 20, Plot: 90, Estab: 190 },
	{ Flat: 200, Plot: 150, Estab: 200 },
	{ Flat: 250, Plot: 135, Estab: 10 },
];

function SalesChart() {
	return (
		<ResponsiveContainer aspect={1}>
			<LineChart data={data}>
				<CartesianGrid />
				<YAxis />
				<Legend />
				<Tooltip />

				<Line dataKey='Flat' stroke='#4274c4' activeDot={{ r: 8 }} />
				<Line dataKey='Plot' stroke='#b99908' activeDot={{ r: 8 }} />
				<Line dataKey='Estab' stroke='#f24867' activeDot={{ r: 8 }} />
			</LineChart>
		</ResponsiveContainer>
	);
}

export default SalesChart;
