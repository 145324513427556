import Icon from '@ant-design/icons';

const component = () => (
	<svg
		width='32'
		height='32'
		viewBox='0 0 32 32'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<rect width='32' height='32' rx='4' fill='#042954' fillOpacity='0.08' />
		<path
			d='M16 26C21.5228 26 26 21.5228 26 16C26 10.4772 21.5228 6 16 6C10.4772 6 6 10.4772 6 16C6 21.5228 10.4772 26 16 26Z'
			stroke='#042954'
			strokeWidth='2'
		/>
		<path d='M9 23L23 9' stroke='#042954' strokeWidth='2' />
	</svg>
);

function CustomBlockIcon(props) {
	return <Icon {...{ props, component }} />;
}

export default CustomBlockIcon;
