import { formatDistance } from 'date-fns';

const withinThisMonth = (date) => {
	const distance = formatDistance(new Date(date), new Date(Date.now()));
	if (!distance.includes('year')) {
		if (!distance.includes('month')) return true;
	}
	return false;
};

const withinThisWeek = (date) => {
	const distance = formatDistance(new Date(date), new Date(Date.now()));
	const res = withinThisMonth(date);
	if (res) {
		if (distance.includes('day')) {
			const days = distance.split(' ')?.[0];
			if (days && +days <= 7) return true;
			return false;
		}
		return true;
	}
	return false;
};

const withinToday = (date) => {
	const distance = formatDistance(new Date(date), new Date(Date.now()));
	const res = withinThisMonth(date);
	if (res) {
		if (!distance.includes('day')) return true;
	}
	return false;
};

export { withinThisMonth, withinThisWeek, withinToday };
