import { Modal } from 'antd';

const featureNotReady = () => {
	Modal.info({
		title: 'Feature Not Ready',
		content: 'coming very soon!',
		maskClosable: true,
		centered: true,
	});
};

export default { featureNotReady };
