import { Fragment, useEffect, useState } from 'react';
import {
	DownloadOutlined,
	PaperClipOutlined,
	SendOutlined,
} from '@ant-design/icons';
import {
	Avatar,
	Button,
	Form,
	Image,
	List,
	PageHeader,
	Space,
	Typography,
	Input,
	message,
	Upload,
} from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getUser } from 'redux/features/auth';
import { v4 as uuid } from 'uuid';
import socket from 'apis/socket';
import chatTime from 'utils/chatTime';
import useAxiosPrivate from 'hooks/use-axios-private';
import { onRemove, uploadChatFile } from 'utils/firebase/storage';
import useWindowDimensions from 'hooks/use-window-dimensions';
import convertBytes from 'utils/convertBytes';
import ScrollToBottom, { useScrollToBottom } from 'react-scroll-to-bottom';

function MessageBodyList({ messageList, user, room }) {
	const scrollToBottom = useScrollToBottom({});

	const download = (path, filename) => {
		// Create a new link
		const anchor = document.createElement('a');
		anchor.href = path;
		anchor.download = filename;
		anchor.target = '_blank';
		anchor.rel = 'noreferrer';
		// Append to the DOM
		document.body.appendChild(anchor);
		// Trigger `click` event
		anchor.click();
		// Remove element from DOM
		document.body.removeChild(anchor);
	};

	useEffect(() => {
		if (messageList) scrollToBottom();
	}, [messageList]);

	return (
		<List
			className='messages-view'
			itemLayout='horizontal'
			dataSource={messageList}
			loading={!room}
			renderItem={(item, idx) => {
				const prev = idx > 0 ? messageList?.[idx - 1] : null;
				const {
					author,
					content,
					created_at: createdAt,
					content_type: contentType,
					meta,
				} = item;
				const sent = author === user.uid;
				const time = chatTime(createdAt);
				const textContent = content.split(' ').map((e, index) => {
					if (e.startsWith('http')) {
						const entities = e.split('/');
						const label = entities[entities.length - 1];
						const linkEl = <a href={e}>{label}</a>;

						return (
							<Fragment key={e}>
								{index === 0 ? linkEl : <> {linkEl}</>}
							</Fragment>
						);
					}

					return <Fragment key={e}>{index === 0 ? e : ` ${e}`}</Fragment>;
				});

				return (
					<>
						{time.day !== chatTime(prev?.created_at).day && (
							<span className='chat-day'>{time.day}</span>
						)}

						<div className={`message ${sent ? 'sent' : 'received'}`}>
							<div className={contentType}>
								{contentType === 'text' ? (
									textContent
								) : (
									<>
										<div>
											{sent ? (
												<span>{content}</span>
											) : (
												<a
													href={meta?.url}
													target='_blank'
													rel='noreferrer'
													download={meta?.name}
												>
													{content}
												</a>
											)}
											{!sent && (
												<Button
													shape='circle'
													size='small'
													icon={<DownloadOutlined />}
													onClick={() =>
														download(meta?.url, meta?.name)
													}
												/>
											)}
										</div>
										<span className='meta'>
											{convertBytes(meta?.size)}
										</span>
									</>
								)}
							</div>
							<span className='time'>{time.at}</span>
						</div>
					</>
				);
			}}
		/>
	);
}

function MessageBox({ room }) {
	const [messageList, setMessageList] = useState([]);
	const user = useSelector(getUser);
	const [form] = Form.useForm();
	const { width } = useWindowDimensions();
	const axiosPrivate = useAxiosPrivate();

	const sendMessage = (newMessage) => {
		socket.emit('send_message', {
			roomid: room?.roomid,
			chatid: room?.chatid,
			...newMessage,
		});
		setMessageList((prev) => [...prev, newMessage]);
	};

	const handleFinish = (values) => {
		if (values.text) {
			const newMessage = {
				id: uuid(),
				author: user.uid,
				content: values.text,
				content_type: 'text',
				created_at: Date.now(),
			};
			sendMessage(newMessage);
		}

		if (values.file?.fileList?.length > 0) {
			const { fileList } = values.file;
			fileList.forEach(({ uid, name, size, type, response }) => {
				const newMessage = {
					id: uuid(),
					author: user.uid,
					content: name,
					content_type: 'file',
					meta: {
						uid,
						name,
						size,
						file_type: type,
						...response,
					},
					created_at: Date.now(),
				};
				sendMessage(newMessage);
			});
		}

		form.resetFields();
	};

	const markAsSeen = async (chatid) => {
		try {
			await axiosPrivate.patch(`/chats/${chatid}`);
		} catch (error) {
			message.error(error?.response?.data?.message);
		}
	};

	useEffect(() => {
		if (room) {
			setMessageList(room.messages);
			socket.emit('join_room', room?.roomid);
			socket.on('receive_message', (data) => {
				markAsSeen(room?.chatid);
				setMessageList((prev) => [...prev, data]);
			});
		}

		return () => {
			socket.off('connect');
			socket.off('disconnect');
			socket.off('receive_message');
			setMessageList([]);
		};
	}, [room, socket]);

	return (
		<>
			<div className='message-header'>
				<PageHeader
					title={
						<Space size={0}>
							<Avatar
								size={width < 578 ? 30 : 45}
								style={{ border: 'thin solid #808080' }}
								src={
									<Image
										src={room?.contact?.photoURL}
										alt={room?.contact?.displayName}
										referrerPolicy='no-referrer'
										preview={false}
									/>
								}
							/>
							<Typography.Title
								level={width < 578 ? 5 : 4}
								style={{ marginBottom: 0, color: '#17185C' }}
							>
								{room?.contact?.displayName}
							</Typography.Title>
						</Space>
					}
					style={{ padding: 0, backgroundColor: 'transparent' }}
				/>
			</div>

			<ScrollToBottom
				className='message-body'
				behavior='smooth'
				initialScrollBehavior='smooth'
			>
				<MessageBodyList {...{ messageList, user, room }} />
			</ScrollToBottom>

			<Form
				form={form}
				name='newMessage'
				className='enter-message'
				onFinish={handleFinish}
			>
				<Form.Item name='text' style={{ marginBottom: 0, flexGrow: 1 }}>
					<Input.TextArea autoSize />
				</Form.Item>

				<Form.Item
					name='file'
					valuePropName='fileLists'
					style={{ marginBottom: 0 }}
				>
					<Upload
						{...{
							customRequest: (e) => uploadChatFile(e, room?.chatid),
							onRemove,
						}}
					>
						<Button icon={<PaperClipOutlined />} />
					</Upload>
				</Form.Item>

				<Button icon={<SendOutlined />} htmlType='submit' />
			</Form>
		</>
	);
}

MessageBodyList.propTypes = {
	messageList: PropTypes.array.isRequired,
	user: PropTypes.any.isRequired,
	room: PropTypes.any.isRequired,
};

MessageBox.propTypes = {
	room: PropTypes.any.isRequired,
};

export default MessageBox;
