import Icon from '@ant-design/icons';
import PropTypes from 'prop-types';

const component = () => (
	<svg
		width='14'
		height='14'
		viewBox='0 0 20 20'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<rect width='20' height='20' rx='2' fill='#0b4e9b' />
	</svg>
);

function PlotIcon(props) {
	const { style } = props;
	return (
		<Icon
			{...{
				style,
				component: () => component(),
			}}
		/>
	);
}

PlotIcon.defaultProps = {
	style: {},
};

PlotIcon.propTypes = {
	style: PropTypes.object,
};

export default PlotIcon;
