import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Layout } from 'antd';
import useWindowDimensions from 'hooks/use-window-dimensions';
import PropTypes from 'prop-types';
import Header from './Header';
import Sidebar from './Sidebar';
import './assets/styles.scss';

export default function Wrapper({ children }) {
	const user = useSelector((state) => state.auth.user);
	const [collapsed, setCollapse] = useState(false);
	const { width } = useWindowDimensions();

	// collapse sidebar on smaller screens
	useEffect(() => {
		if (width <= 1024 && width > 578) setCollapse(true);
		else setCollapse(false);
	}, [width]);

	const toggle = () => setCollapse((prev) => !prev);

	return (
		<Layout>
			<Sidebar {...{ user, collapsed, width }} />
			<Layout className='site-layout'>
				<Header {...{ user, collapsed, toggle, width }} />

				<Layout.Content
					style={{
						padding: '2%',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					{children}
				</Layout.Content>
			</Layout>
		</Layout>
	);
}

Wrapper.propTypes = {
	children: PropTypes.node.isRequired,
};
