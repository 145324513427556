import commingSoon from './commingSoon.png';
import './styles.scss';

function ComingSoon() {
	return (
		<div className='coming-soon'>
			<h1>Comming Soon</h1>

			<img src={commingSoon} alt='comming-soon' />
		</div>
	);
}

export default ComingSoon;
