import Icon from '@ant-design/icons';

const component = () => (
	<svg
		width='22'
		height='20'
		viewBox='0 0 22 20'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M16.5996 5.39961V2.19961C16.5996 0.599609 14.9996 0.599609 14.9996 0.599609H7.07961C7.07961 0.599609 5.31321 0.602809 5.39961 2.19961V5.39961H2.19961C2.19961 5.39961 0.599609 5.39961 0.599609 6.99961V18.1996C0.599609 19.7996 2.19961 19.7996 2.19961 19.7996H19.7996C19.7996 19.7996 21.3996 19.7996 21.3996 18.1996V6.99961C21.3996 5.39961 19.7996 5.39961 19.7996 5.39961H16.5996ZM7.79961 2.99961H14.1996V5.39961H7.79961V2.99961Z'
			fill='#042954'
		/>
	</svg>
);

function CustomBalanceIcon(props) {
	return <Icon {...{ props, component }} />;
}

export default CustomBalanceIcon;
