import { useParams, useNavigate, Link } from 'react-router-dom';
import { useMutation, useQueries, useQueryClient } from '@tanstack/react-query';
import {
	PageHeader,
	message,
	Row,
	Col,
	Card,
	Typography,
	Space,
	Avatar,
	Image,
	Divider,
	List,
	Tooltip,
	Button,
	Popconfirm,
} from 'antd';
import {
	DeleteFilled,
	EditOutlined,
	MailFilled,
	PhoneFilled,
} from '@ant-design/icons';
import { Map } from 'components';
import { CustomBlockIcon, CustomUnblockIcon } from 'components/Icons';
import useAxiosPrivate from 'hooks/use-axios-private';
import useToggle from 'hooks/use-toggle';
import UserTypeUpdateModal from 'components/UserTypeModal';

export default function UserInfo() {
	const { id } = useParams();
	const navigate = useNavigate();
	const axiosPrivate = useAxiosPrivate();
	const queryClient = useQueryClient();
	const [isOpen, toggleOpen] = useToggle();

	const [
		{ data: user, isLoading: userIsLoading },
		{ data: poxes, isLoading: poxesIsLoading },
	] = useQueries({
		queries: [
			{
				queryKey: ['user', id],
				queryFn: async () =>
					(await axiosPrivate.get(`/users/${id}`)).data.user,
			},
			{
				queryKey: ['poxes-data', id],
				queryFn: async () =>
					(await axiosPrivate.get(`/poxes?publishedBy=${id}`)).data.poxes,
			},
		],
	});

	const onError = (error) => {
		message.error(error?.response?.data?.message || error?.message);
	};

	const toggle_status = useMutation({
		mutationFn: async () => {
			const status = user && !user.disabled;
			await axiosPrivate.patch(`/users/${id}/toggle-disable`, {
				status,
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries(
				['user', id],
				['poxes-data', id],
				['users'],
				['poxes-stats'],
				['poxes-data', { showBlocked: true }]
			);

			message.success(`User status has been updated`);
		},
		onError,
	});

	const toggle_type = useMutation({
		mutationFn: async (access_type) => {
			await axiosPrivate.patch(`/users/${id}`, {
				access_type,
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['user', id]);

			message.success(`User type has been updated`);
			toggleOpen();
		},
		onError,
	});

	const delete_user = useMutation({
		mutationFn: async () => {
			await axiosPrivate.delete(`/users/${id}`);
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['users']);
			queryClient.invalidateQueries(['poxes-stats']);
			queryClient.invalidateQueries(['poxes-data', { showBlocked: true }]);

			message.success('Delete successful');
			navigate(-1);
		},
		onError,
	});

	return (
		<>
			<div className='custom-page-header'>
				<PageHeader
					className='site-page-header'
					onBack={() => navigate(-1)}
					title='User Details'
					style={{ flexGrow: 1 }}
				/>
				<Space direction='horizontal'>
					<Typography.Text style={{ marginRight: 16 }}>
						<Typography.Text strong type='secondary'>
							Status:&nbsp;
						</Typography.Text>
						<Typography.Text
							strong
							style={{ textTransform: 'uppercase' }}
						>
							{user?.disabled ? 'Blocked' : 'Active'}
						</Typography.Text>
					</Typography.Text>
					<Space direction='horizontal'>
						<Tooltip
							title={user?.disabled ? 'Enable User' : 'Block User'}
						>
							<Button
								icon={
									user?.disabled ? (
										<CustomUnblockIcon />
									) : (
										<CustomBlockIcon />
									)
								}
								style={{ padding: 0, marginTop: 4 }}
								loading={toggle_status.isLoading}
								onClick={toggle_status.mutate}
							/>
						</Tooltip>
						<Popconfirm
							title='Delete user? Irreversible'
							onConfirm={delete_user.mutate}
						>
							<Button
								type='danger'
								icon={<DeleteFilled />}
								loading={delete_user.isLoading}
								style={{ padding: 0 }}
							/>
						</Popconfirm>
					</Space>
				</Space>
			</div>

			<Row gutter={[16, 24]} style={{ maxWidth: 768 }}>
				<Col xs={24} md={12}>
					<Row gutter={[16, 24]}>
						<Col span={24}>
							<Card loading={userIsLoading}>
								<Space
									direction='horizontal'
									style={{ alignItems: 'flex-start' }}
								>
									<Avatar
										size={50}
										src={
											<Image
												src={user?.photoURL}
												referrerPolicy='no-referrer'
											/>
										}
									/>

									<Space direction='vertical' size={4}>
										<Typography.Title
											level={5}
											style={{ marginBottom: 0 }}
										>
											{user?.displayName}
										</Typography.Title>
										<Space direction='horizontal' size={2}>
											<Typography.Text
												strong
												style={{ whiteSpace: 'nowrap' }}
											>
												ID:
											</Typography.Text>
											<Typography.Text>{user?.id}</Typography.Text>
										</Space>
										<Space>
											<Typography.Text
												strong
												style={{
													textTransform: 'capitalize',
													color: '#042954',
												}}
											>{`${user?.type} User`}</Typography.Text>

											<Button
												type='link'
												icon={<EditOutlined />}
												style={{ padding: 0 }}
												onClick={toggleOpen}
											/>
										</Space>
									</Space>
								</Space>
							</Card>
						</Col>

						<Col span={24}>
							<Card loading={userIsLoading}>
								<Typography.Title
									level={5}
									style={{ textTransform: 'uppercase' }}
								>
									Contact Information
								</Typography.Title>

								<Divider style={{ marginTop: 0 }} />

								<Space direction='vertical' size={8}>
									<Space direction='horizontal'>
										<PhoneFilled />:
										<Typography.Link href='tel:+100 0000 000'>
											{user?.contact_number || '+100 0000 000'}
										</Typography.Link>
									</Space>
									<Space direction='horizontal'>
										<MailFilled />:
										<Typography.Link href={`mailto:${user?.email}`}>
											{user?.email}
										</Typography.Link>
									</Space>
								</Space>
							</Card>
						</Col>

						<Col span={24}>
							<Card loading={poxesIsLoading}>
								<Typography.Title
									level={5}
									style={{ textTransform: 'uppercase' }}
								>
									Poxes Published
								</Typography.Title>

								<Divider style={{ marginTop: 0, marginBottom: 0 }} />

								<List
									dataSource={poxes}
									renderItem={(item) => (
										<List.Item>
											<Link
												to={`/poxes/${item.id}`}
											>{`${item.id} - ${item.name}`}</Link>
										</List.Item>
									)}
								/>
							</Card>
						</Col>
					</Row>
				</Col>

				<Col xs={24} md={12}>
					<Card loading={poxesIsLoading}>
						<div
							style={{
								height: '360px',
								width: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							{poxes?.length > 0 ? (
								<Map positions={poxes.map((pox) => pox?.position)} />
							) : (
								'No Data'
							)}
						</div>
					</Card>
				</Col>
			</Row>

			{user && isOpen && (
				<UserTypeUpdateModal
					{...{
						open: isOpen,
						close: toggleOpen,
						mutation: toggle_type,
						defaultValue: user?.type,
					}}
				/>
			)}
		</>
	);
}
