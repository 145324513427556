import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
	Button,
	Space,
	Divider,
	PageHeader,
	Row,
	Col,
	message,
	Typography,
	Skeleton,
	Tooltip,
	Popconfirm,
} from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import useAxiosPrivate from 'hooks/use-axios-private';
import { getUser } from 'redux/features/auth';
import { onRemove } from 'utils/firebase/storage';
import { Map, StatusUpdateModal } from 'components';
import lifetimeLeft from 'utils/lifetimeLeft';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export default function PoxInfo() {
	const { id } = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const user = useSelector(getUser);
	const axiosPrivate = useAxiosPrivate();
	const queryClient = useQueryClient();
	const [open, setOpen] = useState(false);
	const url = useMemo(
		() => (location.pathname === '/poxes/owned' ? '&ownership=true' : ''),
		[location]
	);

	const { isLoading, data, error } = useQuery(['pox', id], async () => {
		const res = await axiosPrivate.get(`/poxes/${id}`);
		return res.data.pox;
	});

	// delete pox
	const deletePox = useMutation({
		mutationFn: async () => {
			data?.images?.forEach((img) => onRemove(img));
			await axiosPrivate.delete(`/poxes/${id}`);
		},
		onSuccess: () => {
			message.success('Pox deleted');
			queryClient.invalidateQueries(['poxes', { userid: user.uid, url }]);
			queryClient.invalidateQueries(['poxes-data', { showBlocked: true }]);
			queryClient.invalidateQueries(['poxes-stats']);
			navigate(-1);
		},
	});

	// toggle status
	const updateStatus = useMutation({
		mutationFn: async (payload) => {
			await axiosPrivate.patch(`/poxes/${id}`, payload);
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['poxes', { userid: user.uid, url }]);
			queryClient.invalidateQueries(['poxes-data', { showBlocked: true }]);
			queryClient.invalidateQueries(['pox', id]);
			queryClient.invalidateQueries(['poxes-stats']);
			setOpen(false);
			message.info(`Pox updated`);
		},
		onError: (err) => {
			message.error(err?.response?.data?.message || err?.message);
		},
	});

	useEffect(() => {
		if (error) {
			message.error(error?.response?.data?.message || error.message);
			navigate('/poxes');
		}
	}, [error]);

	return (
		<>
			<div className='custom-page-header'>
				<PageHeader
					className='site-page-header'
					style={{ flexGrow: 1 }}
					onBack={() => navigate(-1)}
					title={data?.name}
				/>
				{data && (
					<Space style={{ paddingRight: '4%' }}>
						<Button
							type='primary'
							icon={<EditOutlined />}
							onClick={() => navigate(`/poxes/${id}/edit`)}
						/>
						<Popconfirm
							title='Permanently Delete this Pox?'
							description='Are you sure you want to delete this pox? Cannot be reversed!'
							okText='Yes'
							cancelText='No'
							onConfirm={deletePox.mutate}
						>
							<Button
								type='danger'
								icon={<DeleteOutlined />}
								loading={deletePox.isLoading}
							/>
						</Popconfirm>
					</Space>
				)}
			</div>

			<Row
				style={{ padding: '0 4%' }}
				gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
			>
				{isLoading ? (
					<>
						<Col xs={24} md={12}>
							{[1, 2, 3, 4].map((i) => (
								<Skeleton key={i} active />
							))}
						</Col>
						<Col xs={24} md={12}>
							<Skeleton.Image atcive />
						</Col>
					</>
				) : (
					<>
						<Col xs={24} md={12}>
							{/* <Typography.Title level={5}>
								Publisher's Info
							</Typography.Title>
							<p>
								<Typography.Text type='secondary'>
									Full name
								</Typography.Text>
								:&nbsp;
								{data?.publishedBy.displayName}
							</p>
							<p>
								<Typography.Text type='secondary'>
									Email
								</Typography.Text>
								:&nbsp;
								{data?.publishedBy.email}
							</p>

							<Divider /> */}

							<Typography.Title level={5}>
								Property's Info
							</Typography.Title>
							<p>
								<Typography.Text type='secondary'>
									Pox ID
								</Typography.Text>
								:&nbsp;
								{data?.id}
							</p>
							<p>
								<Typography.Text type='secondary'>
									Property Type
								</Typography.Text>
								:&nbsp;
								{data?.type}
							</p>
							{data?.room_config && (
								<p>
									<Typography.Text type='secondary'>
										Room Config
									</Typography.Text>
									:&nbsp;
									{data.room_config}
								</p>
							)}
							{data?.plot_config && (
								<p>
									<Typography.Text type='secondary'>
										Plot Config
									</Typography.Text>
									:&nbsp;
									{data.plot_config}
								</p>
							)}
							{data?.bath && data?.bath !== null && (
								<p>
									<Typography.Text type='secondary'>
										Bath
									</Typography.Text>
									:&nbsp;
									{data.bath}
								</p>
							)}
							{data?.facing && (
								<p>
									<Typography.Text type='secondary'>
										Entrance Direction
									</Typography.Text>
									:&nbsp;
									{data.facing}
								</p>
							)}
							{data?.floor && (
								<p>
									<Typography.Text type='secondary'>
										Floor
									</Typography.Text>
									:&nbsp; {data.floor}
								</p>
							)}

							<p>
								<Typography.Text type='secondary'>Area</Typography.Text>
								:&nbsp;
								{`${data?.area?.toLocaleString()} ${data?.area_unit}`}
							</p>
							<p>
								<Typography.Text type='secondary'>
									Description
								</Typography.Text>
								:&nbsp;
								{data?.description}
							</p>
							<Divider />

							<Typography.Title level={5}>Cost</Typography.Title>
							<p>
								<Typography.Text type='secondary'>
									Payment Mode
								</Typography.Text>
								:&nbsp;
								{data?.pay_type}
							</p>
							{data?.deposit_cost && (
								<p>
									<Typography.Text type='secondary'>
										Deposit
									</Typography.Text>
									:&nbsp;
									{`${
										data?.currency
									} ${data.deposit_cost.toLocaleString()}`}
								</p>
							)}
							{data?.rent_cost && (
								<p>
									<Typography.Text type='secondary'>
										Rent
									</Typography.Text>
									:&nbsp;
									{`${
										data?.currency
									} ${data.rent_cost.toLocaleString()}`}
								</p>
							)}
							{data?.lease_cost && (
								<p>
									<Typography.Text type='secondary'>
										Lease
									</Typography.Text>
									:&nbsp;
									{`${
										data?.currency
									} ${data.lease_cost.toLocaleString()}`}
								</p>
							)}
							{data?.lease_period && (
								<p>
									<Typography.Text type='secondary'>
										Lease Duration
									</Typography.Text>
									:&nbsp;
									{`${data.lease_period.toLocaleString()} days`}
								</p>
							)}
							{data?.sale_cost && (
								<p>
									<Typography.Text type='secondary'>
										Selling Price
									</Typography.Text>
									:&nbsp;
									{`${
										data?.currency
									} ${data.sale_cost.toLocaleString()}`}
								</p>
							)}
							{data?.turnover_cost && (
								<p>
									<Typography.Text type='secondary'>
										Turnover
									</Typography.Text>
									:&nbsp;
									{`${
										data?.currency
									} ${data.turnover_cost.toLocaleString()}`}
								</p>
							)}

							<Divider />

							<Typography.Title level={5}>
								Contact Details
							</Typography.Title>
							<p>
								<Typography.Text type='secondary'>
									Contact name
								</Typography.Text>
								:&nbsp;
								{data?.contact_name}
							</p>
							<p>
								<Typography.Text type='secondary'>
									Contact number
								</Typography.Text>
								:&nbsp;
								{data?.contact_number}
							</p>
						</Col>
						<Col xs={24} md={12}>
							<div
								style={{
									width: '100%',
									display: 'flex',
									justifyContent:
										data?.status === 'active'
											? 'space-between'
											: 'flex-end',
									alignItems: 'center',
									marginBottom: 16,
								}}
							>
								{data && (
									<>
										{data?.status === 'active' && (
											<div>
												<Typography.Text type='secondary'>
													Day(s) Left
												</Typography.Text>
												:&nbsp;
												<Typography.Text strong>
													{lifetimeLeft(data?.activeFrom)}
												</Typography.Text>
											</div>
										)}

										<div>
											<Typography.Text type='secondary'>
												Status
											</Typography.Text>
											:&nbsp;
											<Typography.Text
												strong
												style={{
													textTransform: 'uppercase',
													color:
														data?.status === 'active'
															? '#38cf2b'
															: data?.status === 'inactive'
															? '#868686'
															: '#f24867',
												}}
											>
												{data?.status}
											</Typography.Text>
											<Tooltip title='update pox status'>
												<Button
													icon={<EditOutlined />}
													type='link'
													onClick={() => setOpen(true)}
												/>
											</Tooltip>
										</div>
									</>
								)}
							</div>

							{/* map select area */}
							<div
								style={{
									width: '100%',
									height: '350px',
									backgroundColor: '#f2f3f8',
									marginBottom: 16,
								}}
							>
								{data && <Map position={data.position} />}
							</div>
							{data?.address && (
								<Typography.Text>
									<Typography.Text type='secondary'>
										Address
									</Typography.Text>
									:&nbsp;
									{data.address}
								</Typography.Text>
							)}

							{data?.status === 'active' && (
								<a
									style={{
										marginTop: 4,
										display: 'block',
										width: 'max-content',
									}}
									href={`${
										process.env.REACT_APP_MAP_URL
									}/${data?.type?.toLowerCase()}/${data?.id}`}
								>
									Preview
								</a>
							)}

							{data && user.isAdmin && (
								<div style={{ marginTop: 48 }}>
									<Typography.Title level={4}>
										Verification Status
									</Typography.Title>

									<Divider style={{ marginTop: 0 }} />

									<Space style={{ marginBottom: 8 }}>
										<Typography.Text>
											{data.isVerified
												? 'Pox has been verified'
												: 'Pox has not been verified'}
										</Typography.Text>

										<Button
											type='primary'
											onClick={() => navigate('verify')}
										>
											{data.isVerified
												? 'Edit verification'
												: 'Begin verification'}
										</Button>
									</Space>

									{data?.verifiedAt && (
										<Typography>
											<Typography.Text type='secondary'>
												Verified on:&nbsp;
											</Typography.Text>
											<Typography.Text>
												{new Date(
													data.verifiedAt
												).toLocaleDateString('en-us', {
													year: 'numeric',
													month: 'short',
													day: 'numeric',
												})}
											</Typography.Text>
										</Typography>
									)}
								</div>
							)}
						</Col>
					</>
				)}
			</Row>

			{data && open && (
				<StatusUpdateModal
					open={open}
					mutation={updateStatus}
					close={() => setOpen(false)}
					data={data}
					isAdmin={user.isAdmin}
				/>
			)}
		</>
	);
}
