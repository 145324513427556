import { Link } from 'react-router-dom';
import { getUser } from 'redux/features/auth';
import { useSelector } from 'react-redux';
import { Button, PageHeader, Spin, Tooltip, Typography } from 'antd';
import { useQuery } from '@tanstack/react-query';
import useAxiosPrivate from 'hooks/use-axios-private';
import coinsPng from 'assets/coins.png';
import './styles.scss';

export default function HomePageUser() {
	const user = useSelector(getUser);
	const axiosPrivate = useAxiosPrivate();

	const { isLoading, data } = useQuery(['poxes-stats', user.uid], async () => {
		const res = await axiosPrivate.get(`/poxes/stats/${user.uid}`);
		return [
			{
				label: 'All Poxes',
				url: 'poxes',
				amount: res.data?.stats.total,
			},
			{
				label: 'Active Poxes',
				url: 'poxes?status=active',
				amount: res.data?.stats.total_active,
			},
			{
				label: 'Inactive Poxes',
				url: 'poxes?status=inactive',
				amount: res.data?.stats.total_inactive,
			},
			{
				label: 'My Poxes',
				url: 'poxes/owned',
				amount: res.data?.stats.total_owned,
			},
		];
	});

	return (
		<div className='home-page'>
			<PageHeader className='site-page-header' title='Poxes Statistics' />

			<div className='statistics-wrapper'>
				{data &&
					data.map(({ label, url, amount }) => (
						<Link to={url} key={label} className='statistic-card'>
							<h4
								style={{
									fontSize: '1.25em',
									whiteSpace: 'wrap',
									lineHeight: 1,
									marginBottom: 5,
								}}
							>
								{label}
							</h4>
							<span style={{ fontSize: '2em', fontWeight: '700' }}>
								{amount}
							</span>
						</Link>
					))}

				{isLoading && <Spin size='large' style={{ marginTop: 16 }} />}
			</div>

			<div className='redeem-card'>
				<Typography level={4}>Earnings</Typography>
				<img src={coinsPng} alt='coins' />
				<div>
					<span>CGE</span>
					<span>{user?.coins}</span>
				</div>
				<Tooltip
					title='Temporarily this function is on Hold'
					trigger={['click']}
				>
					<Button type='button'>Redeem</Button>
				</Tooltip>
			</div>
		</div>
	);
}
