import { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	Button,
	Row,
	Col,
	Divider,
	Form,
	Input,
	Select,
	Radio,
	Space,
	PageHeader,
	Upload,
	Typography,
	message,
	InputNumber,
} from 'antd';
import ImgCrop from 'antd-img-crop';
import { InboxOutlined } from '@ant-design/icons';
import useAxiosPrivate from 'hooks/use-axios-private';
import { customRequest, onRemove } from 'utils/firebase/storage';
import { PoxLoader, Map } from 'components';
import { reverseGeocoding } from 'apis/openCage';
import { onlyNumbersRule, requiredRule } from 'utils/formRules';

export default function EditPox() {
	const [form] = Form.useForm();
	const { id } = useParams();
	const navigate = useNavigate();
	const axiosPrivate = useAxiosPrivate();
	const [isFetching, setIsFetching] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [data, setData] = useState(null);
	const [poxType, setPoxType] = useState(null);
	const [payType, setPayType] = useState(null);
	const [position, setPosition] = useState(null);
	const [isGenerating, setIsGenerating] = useState(false);
	const [areaUnit, setAreaUnit] = useState(null);
	const [countryCode, setCountryCode] = useState(null);
	const [currency, setCurrency] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			setIsFetching(true);
			try {
				const res = await axiosPrivate.get(`/poxes/${id}`);
				setData(res.data?.pox);
				setPoxType(res.data?.pox.type);
				setPayType(res.data?.pox.pay_type);
				setPosition(res.data?.pox.position);
				setAreaUnit(res.data?.pox?.area_unit);
				setCountryCode(res.data?.pox?.contact_number_cc ?? '+91');
				setCurrency(res.data?.pox?.currency);
			} catch (error) {
				message.error(error?.response?.data?.message || error.message);
				navigate(-1);
			} finally {
				setIsFetching(false);
			}
		};
		fetchData();

		return () => {
			setData(null);
			setPoxType(null);
			setPayType(null);
			setPosition(null);
			setAreaUnit(null);
			setCountryCode(null);
			setCurrency(null);
		};
	}, []);

	// const normFile = (e) => {
	// 	const list = e?.fileList.map((f) => {
	// 		if (f.response) {
	// 			return {
	// 				uid: f.uid,
	// 				name: f.name,
	// 				size: f.size,
	// 				status: f.status,
	// 				pathname: f.response.pathname,
	// 				url: f.response.url,
	// 			};
	// 		}
	// 		return f;
	// 	});
	// 	return list;
	// };

	const onChangeUpload = async ({ fileList }) => {
		if (fileList.length === 0) {
			form.setFieldValue('images', []);
		} else {
			const list = fileList.map((f) => {
				if (f.response) {
					return {
						uid: f.uid,
						name: f.name,
						size: f.size,
						status: f.status,
						pathname: f.response.pathname,
						url: f.response.url,
					};
				}
				return f;
			});
			form.setFieldValue('images', list);
		}
	};

	const handleCancel = () => {
		// eslint-disable-next-line no-alert
		const res = window.confirm(
			'Are you sure you want to leave this page? Changes would be lost'
		);
		if (res) navigate(-1);
	};

	const handleOnFinish = async (values) => {
		let stillUploading = false;
		let failedUpload = false;

		values.images?.forEach((e) => {
			if (e.status === 'uploading') stillUploading = true;
			if (e.status === 'error') failedUpload = true;
		});

		if (stillUploading)
			return message.error(
				'Please wait for pending uploads to complete or cancel and retry if taking too long'
			);

		if (failedUpload)
			return message.error(
				'Please remove failed image upload and try again'
			);

		const bodyData = {
			...values,
			id: data.id,
			position,
			area_unit: areaUnit,
			contact_number_cc: countryCode,
			currency,
		};
		setIsEditing(true);

		try {
			const res = await axiosPrivate.patch(`/poxes/${id}`, bodyData);
			message.success(res.data.message);
			navigate(`/poxes/${res.data?.pox?.id}`, { replace: true });
		} catch (error) {
			message.error(error?.response?.data.message || error.message);
		} finally {
			setIsEditing(false);
		}

		return undefined;
	};

	const generateAddress = async () => {
		setIsGenerating(true);
		const res = await reverseGeocoding(position);
		form.setFieldsValue({ address: res?.formatted });
		setIsGenerating(false);
	};

	const SelectAreaUnit = useMemo(
		() => (
			<Select value={areaUnit} onChange={(value) => setAreaUnit(value)}>
				<Select.Option value='SQFTS'>SQFTS</Select.Option>
				<Select.Option value='SQMS'>SQMS</Select.Option>
				<Select.Option value='CENTS'>CENTS</Select.Option>
				<Select.Option value='ACRES'>ACRES</Select.Option>
			</Select>
		),
		[areaUnit]
	);

	const CountryCodes = useMemo(
		() => (
			<Select
				value={countryCode}
				onChange={(value) => setCountryCode(value)}
			>
				<Select.Option value='+91'>+91</Select.Option>
				<Select.Option value='+971'>+971</Select.Option>
			</Select>
		),
		[countryCode]
	);

	const Currencies = useMemo(
		() => (
			<Select value={currency} onChange={(value) => setCurrency(value)}>
				<Select.Option value='INR'>INR</Select.Option>
				<Select.Option value='AED'>AED</Select.Option>
			</Select>
		),
		[countryCode]
	);

	return (
		<div style={{ height: '100%' }}>
			<PageHeader
				className='site-page-header'
				onBack={() => navigate(-1)}
				title={data?.name}
			/>

			{isFetching && <PoxLoader title='Fetching Pox Info...' />}

			{data && (
				<Form
					form={form}
					name='new_pox'
					onFinish={handleOnFinish}
					initialValues={data}
					style={{ height: '100%' }}
				>
					<div style={{ width: '97%', margin: 'auto' }}>
						<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
							<Col xs={24} md={12}>
								<div
									style={{
										backgroundColor: '#f2f3f8',
										padding: 16,
										marginBottom: 16,
									}}
								>
									<Form.Item
										name='images'
										valuePropName='fileList'
										// getValueFromEvent={normFile}
									>
										<ImgCrop quality={0.8} aspect={16 / 10}>
											<Upload.Dragger
												defaultFileList={data.images}
												customRequest={customRequest}
												onRemove={onRemove}
												onChange={onChangeUpload}
												multiple
											>
												<p className='ant-upload-drag-icon'>
													<InboxOutlined />
												</p>
												<p className='ant-upload-text'>
													Click or drag file to this area to upload
												</p>
												{/* <p className='ant-upload-hint'>
													Upload minimum of 3 images
												</p> */}
											</Upload.Dragger>
										</ImgCrop>
									</Form.Item>

									<Form.Item
										name='name'
										label='Property Name'
										rules={[requiredRule('Property Name')]}
										style={{ marginTop: 16 }}
									>
										<Input placeholder='Property name' />
									</Form.Item>

									<Form.Item
										name='type'
										label='Property Type'
										rules={[requiredRule('Property Type')]}
										style={{ marginBottom: 0 }}
									>
										<Radio.Group
											onChange={(e) => {
												setPoxType(e.target.value);
												setPayType(null);
											}}
										>
											<Radio value='Flat'>FLAT</Radio>
											<Radio value='Plot'>PLOT</Radio>
											<Radio value='Estab'>ESTAB</Radio>
										</Radio.Group>
									</Form.Item>
								</div>

								{poxType !== null && (
									<div
										style={{
											backgroundColor: '#f2f3f8',
											padding: 16,
											marginBottom: 16,
										}}
									>
										{poxType === 'Flat' && (
											<>
												<Form.Item
													name='room_config'
													label='Room Config'
													rules={[
														requiredRule('Room Configuration'),
													]}
												>
													<Select>
														<Select.Option value='1RK'>
															1RK
														</Select.Option>
														<Select.Option value='1BHK'>
															1BHK
														</Select.Option>
														<Select.Option value='2BHK'>
															2BHK
														</Select.Option>
														<Select.Option value='3BHK'>
															3BHK
														</Select.Option>
													</Select>
												</Form.Item>

												<Form.Item
													name='bath'
													label='Bath'
													rules={[requiredRule('Number of Bath')]}
												>
													<Select>
														<Select.Option value='1'>
															1
														</Select.Option>
														<Select.Option value='2'>
															2
														</Select.Option>
														<Select.Option value='3'>
															3
														</Select.Option>
														<Select.Option value='4'>
															4
														</Select.Option>
														<Select.Option value='5'>
															5
														</Select.Option>
													</Select>
												</Form.Item>

												<Form.Item
													name='floor'
													label='Floor'
													rules={[requiredRule('Floor')]}
												>
													<Radio.Group>
														<Radio value='Ground'>Ground</Radio>
														<Radio value='First'>First</Radio>
														<Radio value='Middle'>Middle</Radio>
														<Radio value='Top'>Top</Radio>
													</Radio.Group>
												</Form.Item>

												<Form.Item
													name='facing'
													label='Entrance Face Direction'
												>
													<Select>
														<Select.Option value='North'>
															North
														</Select.Option>
														<Select.Option value='East'>
															East
														</Select.Option>
														<Select.Option value='West'>
															West
														</Select.Option>
														<Select.Option value='South'>
															South
														</Select.Option>
														<Select.Option value='North-East'>
															North-East
														</Select.Option>
														<Select.Option value='North-West'>
															North-West
														</Select.Option>
														<Select.Option value='South-East'>
															South-East
														</Select.Option>
														<Select.Option value='South-West'>
															South-West
														</Select.Option>
													</Select>
												</Form.Item>
											</>
										)}
										{poxType === 'Plot' && (
											<Form.Item
												name='plot_config'
												label='Plot Type'
												rules={[requiredRule('Plot Type')]}
											>
												<Radio.Group>
													<Radio value='Residential'>
														Residential
													</Radio>
													<Radio value='Commercial'>
														Commercial
													</Radio>
													<Radio value='Agricultural'>
														Agricultural
													</Radio>
												</Radio.Group>
											</Form.Item>
										)}
										<Form.Item
											name='area'
											label={
												poxType === 'Estab'
													? 'Floor Area'
													: poxType === 'Flat'
													? 'Built-Up Area'
													: 'Plot Area'
											}
											rules={[
												requiredRule(
													poxType === 'Estab'
														? 'Floor Area'
														: poxType === 'Flat'
														? 'Built-Up Area'
														: 'Plot Area'
												),
											]}
										>
											<Input
												placeholder='1000'
												addonAfter={SelectAreaUnit}
											/>
										</Form.Item>
										<Form.Item
											name='pay_type'
											label='Payment Type'
											rules={[requiredRule('Payment Type')]}
											style={{ marginBottom: 0 }}
										>
											<Radio.Group
												onChange={(e) => setPayType(e.target.value)}
											>
												{poxType !== 'Plot' && (
													<Radio value='Rent'>RENT</Radio>
												)}
												<Radio value='Sale'>SALE</Radio>
												{poxType === 'Plot' && (
													<Radio value='Lease'>LEASE</Radio>
												)}
											</Radio.Group>
										</Form.Item>
									</div>
								)}

								{poxType !== null && payType !== null && (
									<div
										style={{
											backgroundColor: '#f2f3f8',
											padding: 16,
											marginBottom: 16,
										}}
									>
										{payType === 'Sale' && (
											<Form.Item
												name='sale_cost'
												label='Sale Cost'
												rules={[
													requiredRule('Sale Cost'),
													onlyNumbersRule(),
												]}
												style={
													poxType !== 'Estab'
														? {
																marginBottom: 0,
														  }
														: {}
												}
											>
												<Input
													addonBefore={Currencies}
													placeholder='1000'
												/>
											</Form.Item>
										)}
										{payType === 'Rent' && (
											<>
												<Form.Item
													name='rent_cost'
													label='Enter Rent'
													rules={[
														requiredRule('Rent Cost'),
														onlyNumbersRule(),
													]}
												>
													<Input
														addonBefore={Currencies}
														placeholder='1000'
													/>
												</Form.Item>
												<Form.Item
													name='deposit_cost'
													label='Deposit'
													rules={[
														requiredRule('Deposit Amount'),
														onlyNumbersRule(),
													]}
													style={
														poxType !== 'Estab'
															? {
																	marginBottom: 0,
															  }
															: {}
													}
												>
													<Input
														addonBefore={Currencies}
														placeholder='1000'
													/>
												</Form.Item>
											</>
										)}
										{poxType === 'Estab' && payType !== null && (
											<Form.Item
												name='turnover_cost'
												label='Turnover'
												rules={[
													requiredRule('Turnover'),
													onlyNumbersRule(),
												]}
												style={{ marginBottom: 0 }}
											>
												<Input
													addonBefore={Currencies}
													placeholder='1000'
												/>
											</Form.Item>
										)}
										{poxType === 'Plot' && payType === 'Lease' && (
											<>
												<Form.Item
													name='lease_cost'
													label='Lease Cost'
													rules={[
														requiredRule('Lease Cost'),
														onlyNumbersRule(),
													]}
												>
													<Input
														addonBefore={Currencies}
														placeholder='1000'
													/>
												</Form.Item>
												<Form.Item
													name='lease_period'
													label='Lease Duration (days)'
													rules={[
														requiredRule('Lease Duration'),
														onlyNumbersRule(),
													]}
													style={{ marginBottom: 0 }}
												>
													<InputNumber min={0} />
												</Form.Item>
											</>
										)}
									</div>
								)}

								<div
									style={{ backgroundColor: '#f2f3f8', padding: 16 }}
								>
									<Form.Item
										name='contact_name'
										label='Contact Name'
										rules={[requiredRule('Contact Name')]}
									>
										<Input />
									</Form.Item>
									<Form.Item
										name='contact_number'
										label='Contact Number'
										rules={[requiredRule('Contact Number')]}
										style={{ marginBottom: 0 }}
									>
										<Input addonBefore={CountryCodes} />
									</Form.Item>
								</div>

								<div
									style={{ backgroundColor: '#f2f3f8', padding: 16 }}
								>
									<Form.Item
										name='ownership'
										label='Property Owned by'
									>
										<Radio.Group>
											<Radio value>Self</Radio>
											<Radio value={false}>Others</Radio>
										</Radio.Group>
									</Form.Item>
								</div>
							</Col>

							<Col xs={24} md={12}>
								<Divider orientation='left'>
									<Typography.Text strong>
										Pick Location
									</Typography.Text>
								</Divider>

								{/* map select area */}
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'center',
										width: '100%',
										height: 350,
										overflow: 'hidden',
										marginBottom: 24,
									}}
								>
									<div
										style={{
											width: '96%',
											height: 'inherit',
											position: 'absolute',
											zIndex: 1,
											margin: 'auto',
										}}
									>
										<Map
											position={data?.position}
											setPosition={setPosition}
										/>
									</div>
								</div>

								<div>
									<div
										style={{
											display: 'flex',
											justifyContent: 'flex-end',
										}}
									>
										<Button
											type='link'
											style={{ fontSize: '0.9em' }}
											loading={isGenerating}
											onClick={generateAddress}
										>
											Auto Generate Address
										</Button>
									</div>
									<Form.Item
										name='address'
										label='Address'
										rules={[requiredRule('Address')]}
									>
										<Input disabled={isGenerating} />
									</Form.Item>

									<Form.Item name='description'>
										<Input.TextArea
											placeholder='Description...'
											showCount
											maxLength={500}
											autoSize={{ minRows: 3, maxRows: 5 }}
											style={{ marginBottom: 32 }}
										/>
									</Form.Item>

									<div className='btns-group'>
										<Space size='large'>
											<Button
												danger
												shape='round'
												htmlType='button'
												onClick={handleCancel}
												style={{ width: 125 }}
											>
												Cancel
											</Button>
											<Button
												type='primary'
												shape='round'
												htmlType='submit'
												style={{ width: 125 }}
											>
												Submit
											</Button>
										</Space>
									</div>
								</div>
							</Col>
						</Row>
					</div>
				</Form>
			)}

			{isEditing && (
				<PoxLoader title='Please wait while your Pox is being updated...' />
			)}
		</div>
	);
}
