import axios from 'axios';

const apikey = process.env.REACT_APP_OPENCAGEDATA_APIKEY;
const baseURL = `https://api.opencagedata.com/geocode/v1`;

const instance = axios.create({
	baseURL,
});

const reverseGeocoding = async ({ lat, lng }) => {
	try {
		const res = await instance.get(`json?key=${apikey}&q=${lat}+${lng}`);
		return res.data?.results?.[0];
	} catch (error) {
		// eslint-disable-next-line no-console
		console.log(error);
		return error?.response;
	}
};

const forwarGeocoding = () => {};

export { reverseGeocoding, forwarGeocoding };
