/* eslint-disable react/prop-types */
import {
	useInfiniteQuery,
	useMutation,
	useQueryClient,
} from '@tanstack/react-query';
import { Button, List, message, Space, Typography } from 'antd';
import CustomDropdown from 'components/CustomDropdown';
import useAxiosPrivate from 'hooks/use-axios-private';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getUser } from 'redux/features/auth';
import { formatDay } from 'utils/chatTime';

const { REACT_APP_MAP_URL } = process.env;

function NotificationsDropdown({ close }) {
	const axiosPrivate = useAxiosPrivate();
	const queryClient = useQueryClient();
	const user = useSelector(getUser);

	const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
		useInfiniteQuery(
			['notifications', { uid: user.uid }],
			async ({ pageParam = 0 }) => {
				const res = await axiosPrivate(`notifications?page=${pageParam}`);
				return res.data;
			},
			{
				getNextPageParam: (lastPage) => lastPage.nextPage ?? undefined,
			}
		);

	const mark_all_as_read = useMutation({
		mutationFn: async () => {
			const res = await axiosPrivate.patch('notifications/read-all');
			return res.data;
		},
		onSuccess: async () => {
			queryClient.invalidateQueries(['notifications']);
			queryClient.invalidateQueries(['unread-notifications']);
			close();
		},
		onError: async (error) => {
			message?.error(error?.response?.data?.message);
		},
	});

	return (
		<CustomDropdown close={close}>
			<List
				itemLayout='horizontal'
				loadMore={
					hasNextPage ? (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<Button
								type='link'
								loading={isFetchingNextPage}
								disabled={!hasNextPage || isFetchingNextPage}
								onClick={() => fetchNextPage()}
							>
								Load More
							</Button>
						</div>
					) : (
						data?.pages?.[1] && (
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								<Typography.Text type='secondary'>
									No more data
								</Typography.Text>
							</div>
						)
					)
				}
				header={
					<div
						style={{
							width: '100%',
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							padding: '0 8px',
						}}
					>
						<Typography.Title level={4} style={{ marginBottom: 0 }}>
							Notifications
						</Typography.Title>

						{data?.pages[0]?.notifications.length > 0 && (
							<Button
								type='link'
								style={{ padding: 0, fontSize: '0.9em' }}
								loading={mark_all_as_read.isLoading}
								onClick={mark_all_as_read.mutate}
							>
								mark all as read
							</Button>
						)}
					</div>
				}
				loading={isLoading}
				dataSource={data?.pages?.reduce((a, b) => {
					if (b?.notifications) {
						return [...a, ...b.notifications];
					}
					return a;
				}, [])}
				renderItem={(item) => (
					<List.Item style={{ padding: '4px 8px' }}>
						<Space
							direction='vertical'
							size={0}
							style={{ width: '100%' }}
						>
							<Typography.Text strong style={{ fontSize: '0.855em' }}>
								{item?.notification_type}
							</Typography.Text>

							<div
								style={{
									width: '100%',
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
									gap: 8,
								}}
							>
								{item?.notification_type === 'Geofence_Alert' ? (
									<a
										href={`${REACT_APP_MAP_URL}/${
											item?.about.slice(0, 1) === 'F'
												? 'flat'
												: item?.about.slice(0, 1) === 'P'
												? 'plot'
												: 'estab'
										}/${item?.about}`}
									>
										{item?.message}
									</a>
								) : (
									<Link to={`/poxes/${item.about}`}>
										{item?.message}
									</Link>
								)}

								<Typography.Text
									style={{
										fontSize: '0.8em',
										width: 56,
										textAlign: 'center',
									}}
								>
									{formatDay(new Date(item?.createdAt))}
								</Typography.Text>
							</div>
						</Space>
					</List.Item>
				)}
				className='custom-scroll-bar'
				style={{ maxHeight: 400, overflow: 'auto' }}
			/>
		</CustomDropdown>
	);
}

export default NotificationsDropdown;
