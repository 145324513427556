import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, message, Select } from 'antd';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { reverseGeocoding } from 'apis/openCage';
import { Loader, Map } from 'components';
import useAxiosPrivate from 'hooks/use-axios-private';
import useToggle from 'hooks/use-toggle';
import useURLQuery from 'hooks/use-url-query';

const category_options = [
	'Road/Highway construction',
	'Metro/Railway Project',
	'Airport Project',
	'Shopping Complex',
	'Schools/College/University',
	'Hospital',
	'Others',
];

function FormView() {
	const [form] = Form.useForm();
	const [isGenerating, toggleLoading] = useToggle();
	const [position, setPosition] = useState({
		lat: 20.5937,
		lng: 78.9629,
	});
	const axiosPrivate = useAxiosPrivate();
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const URLQuery = useURLQuery();
	const id = URLQuery.get('id');

	const { data, isLoading } = useQuery(
		['project-info', { id }],
		async () => {
			if (id) {
				const res = await axiosPrivate(`/project-info/${id}`);
				return res.data;
			}
			return null;
		},
		{
			onError: (error) => {
				message.error(error?.response?.data.message || error.message);
			},
		}
	);

	const generateAddress = async () => {
		toggleLoading();
		const res = await reverseGeocoding(position);
		form.setFieldsValue({ location: res?.formatted });
		toggleLoading();
	};

	const clear = () => {
		form.resetFields();
	};

	const onSuccess = (result) => {
		message.success(result.message);
		queryClient.invalidateQueries(['project-info']);
		navigate(`/project-info?current=all`);
	};

	const onError = (error) => {
		message.error(error?.response?.data.message || error.message);
	};

	const save_info = useMutation({
		mutationFn: async (payload) => {
			const res = id
				? await axiosPrivate.patch(`/project-info/${id}`, payload)
				: await axiosPrivate.post(`/project-info`, payload);
			return res.data;
		},
		onSuccess,
		onError,
	});

	const finish = (values) => {
		const bodyData = { ...values, position };
		save_info.mutate(bodyData);
	};

	useEffect(() => {
		if (id && data) {
			setPosition({ lat: data?.position?.lat, lng: data?.position?.lng });
			form.setFieldsValue(data);
		}
	}, [id, data]);

	return (
		<div style={{ maxWidth: 840 }}>
			<div
				style={{
					width: '100%',
					height: '420px',
					marginBottom: 24,
				}}
			>
				{isLoading ? (
					<Loader />
				) : !isLoading && id && data ? (
					<Map
						position={{
							lat: data?.position?.lat,
							lng: data?.position?.lng,
						}}
						setPosition={setPosition}
					/>
				) : (
					<Map position={position} setPosition={setPosition} />
				)}
			</div>

			<Form
				name='project_info'
				form={form}
				onFinish={finish}
				labelCol={{ span: 3 }}
				wrapperCol={{ span: 21 }}
				style={{ padding: '0 2%' }}
			>
				<div className='auto-gen-wrapper'>
					<div className='auto-gen-btn'>
						<Button
							type='link'
							style={{ fontSize: '0.9em' }}
							loading={isGenerating}
							onClick={generateAddress}
						>
							Auto Generate Address
						</Button>
					</div>
					<Form.Item
						name='location'
						label='Location'
						rules={[
							{
								required: true,
								message: 'Location is required',
							},
						]}
					>
						<Input
							disabled={isGenerating}
							placeholder='Location address or auto-generate one...'
						/>
					</Form.Item>
				</div>

				<Form.Item
					name='title'
					label='Title'
					rules={[
						{
							required: true,
							message: 'Title is required',
						},
					]}
				>
					<Input placeholder='Title identifier...' />
				</Form.Item>

				<Form.Item
					name='category'
					label='Category'
					rules={[
						{
							required: true,
							message: 'Pick a Category',
						},
					]}
				>
					<Select placeholder='Category...'>
						{category_options.map((option) => (
							<Select.Option key={option} value={option}>
								{option}
							</Select.Option>
						))}
					</Select>
				</Form.Item>

				<Form.Item name='details' label='Details'>
					<Input.TextArea rows={6} placeholder='Details...' />
				</Form.Item>

				<Form.Item name='more_info_url' label='URL'>
					<Input type='url' placeholder='URL for more info...' />
				</Form.Item>

				<div
					style={{
						display: 'flex',
						justifyContent: 'flex-end',
						gap: 16,
					}}
				>
					<Button
						type='default'
						size='medium'
						onClick={clear}
						style={{ paddingRight: '2.5em', paddingLeft: '2.5em' }}
					>
						Clear all
					</Button>
					<Button
						type='primary'
						htmlType='submit'
						size='medium'
						loading={save_info.isLoading}
						style={{ paddingRight: '2.5em', paddingLeft: '2.5em' }}
					>
						{id ? 'Update' : 'Save'}
					</Button>
				</div>
			</Form>
		</div>
	);
}

export default FormView;
