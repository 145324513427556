import Icon from '@ant-design/icons';
import PropTypes from 'prop-types';

const component = () => (
	<svg
		width='14'
		height='14'
		viewBox='0 0 20 20'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M17.8851 0H7.37816C6.20861 0 5.26223 0.899 5.26223 2.01V7.647L0.31245 12.289C0.164141 12.4283 0.0627518 12.6064 0.0211496 12.8006C-0.0204525 12.9947 -0.000391917 13.1963 0.0787855 13.3796C0.157963 13.5629 0.292688 13.7197 0.465862 13.8302C0.639036 13.9407 0.842852 13.9998 1.05144 14V19C1.05144 19.2652 1.16235 19.5196 1.35977 19.7071C1.55719 19.8946 1.82495 20 2.10414 20H18.9473C19.2265 20 19.4943 19.8946 19.6917 19.7071C19.8891 19.5196 20 19.2652 20 19V2.009C20 0.899 19.0547 0 17.8851 0ZM9.39091 13.111V18H3.15684V12.432L6.30125 9.483L9.39091 12.486V13.111ZM11.5784 7H9.47302V5H11.5784V7ZM15.7892 15H13.6838V13H15.7892V15ZM15.7892 11H13.6838V9H15.7892V11ZM15.7892 7H13.6838V5H15.7892V7Z'
			fill='#0b4e9b'
		/>
	</svg>
);

function FlatIcon(props) {
	const { style } = props;
	return (
		<Icon
			{...{
				style,
				component: () => component(),
			}}
		/>
	);
}

FlatIcon.defaultProps = {
	style: {},
};

FlatIcon.propTypes = {
	style: PropTypes.object,
};

export default FlatIcon;
