import { useSelector } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom';
import { getUser } from 'redux/features/auth';

function AlreadyLoggedIn() {
	const user = useSelector(getUser);

	return user ? <Navigate to='/' /> : <Outlet />;
}

export default AlreadyLoggedIn;
