import { Link, useNavigate } from 'react-router-dom';
import { PageHeader } from 'antd';
import useURLQuery from 'hooks/use-url-query';
import TableView from './TableView';
import FormView from './FormView';
import './styles.scss';

const tabitems = [
	{ id: 'all', title: 'all project info', label: 'All' },
	{ id: 'add_new', title: 'add new project info', label: 'Add New' },
];

function ProjectInfo() {
	const query = useURLQuery();
	const navigate = useNavigate();

	return (
		<>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<PageHeader
					className='site-page-header'
					onBack={() => navigate(-1)}
					title="Project's Info"
				/>
			</div>

			<div className='tab-menu'>
				{tabitems.map((item) => (
					<Link
						key={item.id}
						to={`/project-info?current=${item.id}`}
						style={{ color: 'inherit' }}
					>
						<button
							title={item.title}
							type='button'
							className={
								query.get('current') === item.id ? 'active' : ''
							}
						>
							{item.id === 'add_new' && query.get('id')
								? 'Edit'
								: item.label}
						</button>
					</Link>
				))}
			</div>

			{query.get('current') === 'all' && <TableView />}
			{query.get('current') === 'add_new' && <FormView />}
		</>
	);
}

export default ProjectInfo;
