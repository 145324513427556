/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import L from 'leaflet';
import { Marker, useMapEvents } from 'react-leaflet';
import { ReactComponent as NavigatorIcon } from 'assets/bx_current-location.svg';
import { Spin } from 'antd';
import useToggle from 'hooks/use-toggle';
import locationIcon from './marker.svg';

const LocationIcon = L.icon({
	iconUrl: locationIcon,
	iconRetinaUrl: locationIcon,
	iconSize: [60, 50],
	className: 'marker',
});

function Navigator({ add_marker }) {
	const mapEvents = useMapEvents({});
	const [current, setCurrent] = useState(null);
	const [isLoading, toggleLoading] = useToggle(false);

	const navigate_to_user_location = () => {
		toggleLoading();
		navigator.geolocation.getCurrentPosition((position) => {
			setCurrent({
				lat: position.coords.latitude,
				lng: position.coords.longitude,
			});
			mapEvents.setView(
				{ lat: position.coords.latitude, lng: position.coords.longitude },
				14,
				{
					animate: true,
					pan: {
						duration: 1,
					},
				}
			);
			toggleLoading();
		});
	};

	return (
		<>
			<div className='navigator'>
				<button
					onClick={navigate_to_user_location}
					title='current location'
					type='button'
				>
					{isLoading ? <Spin size='small' /> : <NavigatorIcon />}
				</button>
			</div>

			{add_marker && current && (
				<Marker position={current} icon={LocationIcon} />
			)}
		</>
	);
}

export default Navigator;
