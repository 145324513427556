import { Button, PageHeader, Tooltip, Typography } from 'antd';
import coinsPng from 'assets/coins.png';
import { getUser } from 'redux/features/auth';
import { useSelector } from 'react-redux';
import './styles.scss';

export default function Earnings() {
	const user = useSelector(getUser);
	return (
		<div className='earning-page'>
			<PageHeader className='site-page-header' title='Earnings' />

			<div className='redeem-card'>
				<Typography level={4}>Earnings</Typography>
				<img src={coinsPng} alt='coins' />
				<div>
					<span>CGE</span>
					<span>{user?.coins}</span>
				</div>
				<Tooltip
					title='Temporarily this function is on Hold'
					trigger={['click']}
				>
					<Button type='button'>Redeem</Button>
				</Tooltip>
			</div>
		</div>
	);
}
