export default function NotFound() {
	return (
		<div
			style={{
				minHeight: '100%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<h1>
				404 | <span style={{ fontSize: '50%' }}>Page not found</span>
			</h1>
		</div>
	);
}
