/* eslint-disable react/prop-types */
import { useState } from 'react';
import { Modal, Radio, Space, Typography } from 'antd';

function UserTypeUpdateModal({ open, close, mutation, defaultValue }) {
	const [value, setValue] = useState(defaultValue);

	const onChange = (e) => setValue(e.target.value);

	return (
		<Modal
			title={<Typography.Text strong>Change User Type</Typography.Text>}
			open={open}
			onCancel={close}
			onOk={() => mutation.mutate(value)}
			okButtonProps={{
				loading: mutation.isLoading,
				disabled: value === defaultValue,
			}}
			style={{ maxWidth: 300, margin: 'auto' }}
			centered
			destroyOnClose
		>
			<Radio.Group {...{ value, onChange }}>
				<Space direction='vertical'>
					<Radio value='basic'>Basic</Radio>
					<Radio value='premium'>Premium</Radio>
					<Radio value='business'>Business</Radio>
					<Radio value='builder'>Builder</Radio>
				</Space>
			</Radio.Group>
		</Modal>
	);
}

export default UserTypeUpdateModal;
