/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
import {
	getAuth,
	GoogleAuthProvider,
	FacebookAuthProvider,
	signInWithPopup,
	signOut,
	signInWithEmailAndPassword,
} from 'firebase/auth';
import firebaseApp from 'config/firebase';
import { message } from 'antd';

const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

export const auth = getAuth(firebaseApp);

export const logInWithGoogle = () =>
	signInWithPopup(auth, googleProvider)
		.then((userCred) => ({
			user: {
				uid: userCred?.uid,
				displayName: userCred?.displayName,
				email: userCred?.email,
				phoneNumber: userCred?.phoneNumber,
				photoURL: userCred?.photoURL,
			},
			token: userCred?._tokenResponse.idToken,
		}))
		.catch((err) => {
			console.error({ err });
			if (err?.code === 'auth/user-disabled') {
				message.info(
					'Your account has been disable, contact the Admin to re-enable it'
				);
			}
		});

export const loginWithFacebook = () =>
	signInWithPopup(auth, facebookProvider)
		.then((userCred) => ({
			user: {
				uid: userCred?.uid,
				displayName: userCred?.displayName,
				email: userCred?.email,
				phoneNumber: userCred?.phoneNumber,
				photoURL: userCred?.photoURL,
			},
			token: userCred?._tokenResponse.idToken,
		}))
		.catch((err) => {
			console.error({ err });
			if (err?.code === 'auth/account-exists-with-different-credential') {
				message.info(
					'Email has been used to sign in with a different method'
				);
			}
			if (err?.code === 'auth/user-disabled') {
				message.info(
					'Your account has been disable, contact the Admin to re-enable it'
				);
			}
		});

export const logInWithCreds = ({ email, password }) =>
	signInWithEmailAndPassword(auth, email, password)
		.then((userCred) => ({
			user: {
				uid: userCred.uid,
				displayName: userCred.displayName,
				email: userCred.email,
				phoneNumber: userCred.phoneNumber,
				photoURL: userCred.photoURL,
			},
			token: userCred._tokenResponse.idToken,
		}))
		.catch((err) => {
			console.error({ err });
			message.error(err.code);
		});

export const logOut = () =>
	signOut(auth)
		.then((res) => res)
		.catch((err) => console.error(err));
