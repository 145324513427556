import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from 'utils/firebase/auth';
import { getUser, logInSuccess } from 'redux/features/auth';
import { Loader } from 'components';
import axios from 'apis/axios';
import { message } from 'antd';

function PersistLogin() {
	const [isLoading, setisLoading] = useState(true);
	const dispatch = useDispatch();
	const user = useSelector(getUser);

	useEffect(() => {
		const refresh = async () =>
			onAuthStateChanged(auth, async (userCred) => {
				setisLoading(true);
				try {
					if (userCred) {
						const { data } = await axios.post('/auth', {
							token: await userCred.getIdToken(),
						});

						dispatch(
							logInSuccess({
								user: {
									uid: userCred?.uid,
									displayName: userCred?.displayName,
									email: userCred?.email,
									phoneNumber: userCred?.phoneNumber,
									photoURL: userCred?.photoURL,
									isAdmin: data.user.isAdmin,
									coins: data.user.coins,
									admin_type: data.user?.admin_type,
									access_type: data.user?.access_type,
								},
								token: await userCred.getIdToken(),
							})
						);
					}
				} catch (err) {
					// eslint-disable-next-line no-console
					console.error({ err });
					if (err?.code === 'auth/user-disabled') {
						message.info(
							'Your account has been disable, contact the Admin to re-enable it'
						);
					}
				} finally {
					setisLoading(false);
				}
			});

		if (!user) refresh();
	}, []);

	return isLoading ? <Loader /> : <Outlet />;
}

export default PersistLogin;
