import { Fragment, useState } from 'react';
import L from 'leaflet';
import {
	MapContainer,
	TileLayer,
	Rectangle,
	FeatureGroup,
	Tooltip,
	Marker,
} from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import PropTypes from 'prop-types';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import Navigator from 'components/LeafletMap/Navigator';
import locationIcon from 'components/LeafletMap/marker.svg';

const LocationIcon = L.icon({
	iconUrl: locationIcon,
	iconRetinaUrl: locationIcon,
	iconSize: [30, 30],
	className: `marker `,
});

function GeofenceMap({ fences, createFence, editFence, deleteFence }) {
	const [storedFence] = useState(fences);

	const onCreated = (e) => {
		const newFence = {
			id: e.layer._leaflet_id,
			bounds: [
				[e.layer._bounds._southWest.lat, e.layer._bounds._southWest.lng],
				[e.layer._bounds._northEast.lat, e.layer._bounds._northEast.lng],
			],
			layer_type: e.layerType,
			color: e.layer.options?.color,
		};
		createFence(newFence);
	};

	const onEdited = (e) => {
		e.layers.eachLayer((a) => {
			editFence({
				id: a.options.id ?? a._leaflet_id,
				payload: {
					bounds: [
						[a._bounds._southWest.lat, a._bounds._southWest.lng],
						[a._bounds._northEast.lat, a._bounds._northEast.lng],
					],
				},
			});
		});
	};

	const onDeleted = (e) => {
		e.layers.eachLayer((a) => {
			deleteFence(a.options.id ?? a._leaflet_id);
		});
	};

	return (
		<MapContainer
			center={[50.5, 30.5]}
			zoom={3}
			minZoom={3}
			worldCopyJump
			tap={false}
			style={{ minHeight: '100%', width: '100%' }}
		>
			<FeatureGroup>
				<EditControl
					position='topright'
					onCreated={onCreated}
					onEdited={onEdited}
					onDeleted={onDeleted}
					draw={{
						marker: false,
						circle: false,
						circlemarker: false,
						polygon: false,
						polyline: false,
					}}
				/>
				{storedFence?.map((fence) => (
					<Fragment key={fence.id}>
						<Rectangle
							bounds={fence.bounds}
							id={fence.id}
							color={fence.color}
						>
							<Tooltip>
								<span style={{ color: fence.color }}>{fence.name}</span>
							</Tooltip>
						</Rectangle>
						<Marker
							icon={LocationIcon}
							position={[
								(fence.bounds[0][0] + fence.bounds[1][0]) / 2,
								(fence.bounds[0][1] + fence.bounds[1][1]) / 2,
							]}
						>
							<Tooltip>
								<span style={{ color: fence.color }}>{fence.name}</span>
							</Tooltip>
						</Marker>
					</Fragment>
				))}
			</FeatureGroup>
			<TileLayer
				attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attributions">CARTO</a>'
				url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
			/>
			<Navigator />
		</MapContainer>
	);
}

GeofenceMap.propTypes = {
	fences: PropTypes.any.isRequired,
	createFence: PropTypes.any.isRequired,
	editFence: PropTypes.any.isRequired,
	deleteFence: PropTypes.any.isRequired,
};

export default GeofenceMap;
