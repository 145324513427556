import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
	Button,
	Skeleton,
	PageHeader,
	Row,
	Col,
	message,
	Typography,
	Input,
	Image,
	Form,
	InputNumber,
} from 'antd';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import useAxiosPrivate from 'hooks/use-axios-private';
import Map from 'components/LeafletMap';
import { onlyNumbersRule, requiredRule } from 'utils/formRules';
import defaultUx from './ucpox_ux.png';
import './styles.scss';

export default function Poxverif() {
	const { id } = useParams();
	const navigate = useNavigate();
	const axiosPrivate = useAxiosPrivate();
	const queryClient = useQueryClient();

	const { data, isLoading: dataIsLoading } = useQuery(
		['poxes-data', id],
		async () => {
			const res = await axiosPrivate.get(`/poxes/${id}`);
			return res.data.pox;
		}
	);

	// recommend price
	const [recLoading, setRecLoading] = useState(false);
	const onFinish = async (values) => {
		setRecLoading(true);
		try {
			await axiosPrivate.patch(`/poxes/${id}`, {
				recommended: values,
				effective_cost: values.effective_cost,
				isVerified: true,
			});
			message.success('Pox has been verified successfully');
			queryClient.invalidateQueries(['poxes-data', { showBlocked: true }]);
			queryClient.invalidateQueries(['poxes-data', id]);
			queryClient.invalidateQueries(['poxes-stats']);
			navigate(-1);
		} catch (error) {
			message.error(error?.response?.data.message || error.message);
		} finally {
			setRecLoading(false);
		}
	};

	return (
		<>
			<PageHeader
				className='site-page-header'
				style={{ marginBottom: 16 }}
				onBack={() => navigate(-1)}
				title='Pox Verification'
			/>

			<Row gutter={[24, 32]} className='row-styles'>
				{dataIsLoading ? (
					<>
						<Col xs={{ span: 24 }} md={{ span: 12 }}>
							{[1, 2, 3].map((_) => (
								<Skeleton key={_} active />
							))}
						</Col>
						<Col xs={{ span: 24 }} md={{ span: 12 }}>
							<Skeleton.Image atcive />
						</Col>
						<Col xs={{ span: 24 }} md={{ span: 12 }}>
							<Row gutter={[16, 16]}>
								{[1, 2, 3, 4].map((_) => (
									<Col span={12} key={_}>
										<Skeleton.Image active />
									</Col>
								))}
							</Row>
						</Col>
						<Col xs={{ span: 24 }} md={{ span: 12 }}>
							{[1, 2].map((_) => (
								<Skeleton key={_} active />
							))}
						</Col>
					</>
				) : (
					data && (
						<>
							<Col xs={{ span: 24 }} md={{ span: 12 }}>
								<Typography.Title level={3}>
									Pox Details
								</Typography.Title>
								<p>
									<Typography.Text type='secondary'>
										Property Name: &nbsp;
									</Typography.Text>
									<Typography.Text>&nbsp;{data?.name}</Typography.Text>
								</p>
								<p>
									<Typography.Text type='secondary'>
										Property Type: &nbsp;
									</Typography.Text>
									<Typography.Text>{data?.type}</Typography.Text>
								</p>
								<p>
									<Typography.Text type='secondary'>
										Publisher Name: &nbsp;
									</Typography.Text>
									<Typography.Text>
										{data?.publishedBy.displayName}
									</Typography.Text>
								</p>
								<p>
									<Typography.Text type='secondary'>
										Contact Detail: &nbsp;
									</Typography.Text>
									<Typography.Text>
										{data?.contact_number}
									</Typography.Text>
								</p>
								{data?.rent_cost && (
									<p>
										<Typography.Text type='secondary'>
											Rent Cost: &nbsp;
										</Typography.Text>
										<Typography.Text>{`${data.rent_cost}`}</Typography.Text>
									</p>
								)}
								{data?.deposit_cost && (
									<p>
										<Typography.Text type='secondary'>
											Deposit Cost: &nbsp;
										</Typography.Text>
										<Typography.Text>
											{`${data.deposit_cost}`}
										</Typography.Text>
									</p>
								)}
								{data?.sale_cost && (
									<p>
										<Typography.Text type='secondary'>
											Sale Cost: &nbsp;
										</Typography.Text>
										<Typography.Text>{`${data.sale_cost}`}</Typography.Text>
									</p>
								)}
								{data?.turnover_cost && (
									<p>
										<Typography.Text type='secondary'>
											Turnover Cost: &nbsp;
										</Typography.Text>
										<Typography.Text>
											{`${data.turnover_cost}`}
										</Typography.Text>
									</p>
								)}
								{data?.lease_cost && (
									<p>
										<Typography.Text type='secondary'>
											Lease Cost: &nbsp;
										</Typography.Text>
										<Typography.Text>
											{`${data.lease_cost}`}
										</Typography.Text>
									</p>
								)}
								{data?.lease_period && (
									<p>
										<Typography.Text type='secondary'>
											Lease Period (days): &nbsp;
										</Typography.Text>
										<Typography.Text>
											{data.lease_period}
										</Typography.Text>
									</p>
								)}
								<p>
									<Typography.Text type='secondary'>
										Address: &nbsp;
									</Typography.Text>
									<Typography.Text>{data?.address}</Typography.Text>
								</p>
							</Col>
							<Col xs={{ span: 24 }} md={{ span: 12 }}>
								<Typography.Title level={3}>
									Pox Location
								</Typography.Title>

								<div
									style={{
										width: '100%',
										height: '320px',
									}}
								>
									{data && (
										<div className='map-wrapper'>
											<Map position={data.position} />
										</div>
									)}
								</div>
							</Col>
							<Col xs={{ span: 24 }} md={{ span: 12 }}>
								<Typography.Title level={3}>
									Pox Gallery
								</Typography.Title>

								<Row gutter={[8, 8]} className='images-wrapper'>
									{[1, 2, 3].map((_, idx) => (
										<Col span={12} key={_}>
											<Image
												src={data?.images[idx]?.url || defaultUx}
												className='pox-img'
												height={125}
												width='100%'
											/>
										</Col>
									))}
									<Col span={12}>
										<div className='more'>
											+
											{data && data.images.length - 3 > 0
												? data.images.length - 3
												: 0}
										</div>
									</Col>
								</Row>
							</Col>
							<Col xs={{ span: 24 }} md={{ span: 12 }}>
								<Typography.Title level={3}>
									{data?.isVerified
										? 'Update Recommended Price'
										: 'Add Recommended Price'}
								</Typography.Title>

								<Form
									onFinish={onFinish}
									initialValues={
										data.isVerified ? data?.recommended : {}
									}
									style={{ maxWidth: 420 }}
								>
									{data.rent_cost && (
										<Form.Item
											label='Rent Cost'
											name='rent_cost'
											rules={[
												requiredRule('Recommended rent cost'),
												onlyNumbersRule(),
											]}
										>
											<Input addonBefore={data?.currency} />
										</Form.Item>
									)}
									{data.deposit_cost && (
										<Form.Item
											label='Deposit Cost'
											name='deposit_cost'
											rules={[
												requiredRule('Recommended deposti cost'),
												onlyNumbersRule(),
											]}
										>
											<Input addonBefore={data?.currency} />
										</Form.Item>
									)}
									{data.sale_cost && (
										<Form.Item
											label='Sale Cost'
											name='sale_cost'
											rules={[
												requiredRule('Recommended sale cost'),
												onlyNumbersRule(),
											]}
										>
											<Input addonBefore={data?.currency} />
										</Form.Item>
									)}
									{data.turnover_cost && (
										<Form.Item
											label='Turnover Cost'
											name='turnover_cost'
											rules={[
												requiredRule('Recommended turnover cost'),
												onlyNumbersRule(),
											]}
										>
											<Input addonBefore={data?.currency} />
										</Form.Item>
									)}
									{data.lease_cost && (
										<Form.Item
											label='Lease Cost'
											name='lease_cost'
											rules={[
												requiredRule('Recommended lease cost'),
												onlyNumbersRule(),
											]}
										>
											<Input addonBefore={data?.currency} />
										</Form.Item>
									)}
									{data.lease_period && (
										<Form.Item
											label='Lease Period (days)'
											name='lease_period'
											rules={[
												requiredRule('Recommended lease period'),
												onlyNumbersRule(),
											]}
										>
											<InputNumber min={0} />
										</Form.Item>
									)}
									<Form.Item
										label='Effective cost (in 2023)'
										name='effective_cost'
										rules={[
											requiredRule('Effective cost in 2023'),
											onlyNumbersRule(),
										]}
									>
										<Input addonBefore={data?.currency} />
									</Form.Item>

									<Button
										htmlType='submit'
										type='primary'
										block
										loading={recLoading}
									>
										{data.isVerified
											? 'Update Recommendation'
											: 'Recommend Price'}
									</Button>
								</Form>
							</Col>
						</>
					)
				)}
			</Row>
		</>
	);
}
