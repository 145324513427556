import { useMemo } from 'react';
import { Button, message, Popconfirm, Space, Table } from 'antd';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { DeleteOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { getUser } from 'redux/features/auth';
import useAxiosPrivate from 'hooks/use-axios-private';
import useWindowDimensions from 'hooks/use-window-dimensions';

function TableView() {
	const user = useSelector(getUser);
	const axiosPrivate = useAxiosPrivate();
	const queryClient = useQueryClient();
	const { width } = useWindowDimensions();

	const { isLoading, data } = useQuery(
		['admins'],
		async () => {
			const res = await axiosPrivate(`/users/admin`);
			return res.data?.data;
		},
		{
			onError: (error) => {
				message.error(error?.response?.data.message || error.message);
			},
		}
	);

	const delete_info = useMutation({
		mutationFn: async (id) => {
			const res = await axiosPrivate.delete(`/users/admin/${id}`);
			return res.data;
		},
		onSuccess: (result) => {
			message.success(result.message);
			queryClient.invalidateQueries(['admins']);
		},
		onError: (error) => {
			message.error(error?.response?.data.message || error.message);
		},
	});

	const columns = useMemo(() => {
		const main = [
			{
				title: 'ID',
				key: 'id',
				dataIndex: 'id',
			},
			{ title: 'Admin Name', key: 'displayName', dataIndex: 'displayName' },
			{ title: 'Email ID', key: 'email', dataIndex: 'email' },
		];
		const type = {
			title: 'Type',
			key: 'type',
			dataIndex: 'type',
		};
		const action = {
			title: 'Action',
			render: (_text, record) => (
				<Space size='small'>
					<Popconfirm
						placement='topLeft'
						title='Delete Admin'
						description='Are you sure you want to delete this?'
						onConfirm={() => delete_info.mutate(record.uid)}
						okText='Yes'
						cancelText='No'
					>
						{user?.admin_type === 'super_admin' &&
							record.type !== 'super_admin' && (
								<Button
									type='text'
									danger
									icon={<DeleteOutlined />}
									style={{ padding: 0 }}
								/>
							)}
					</Popconfirm>
				</Space>
			),
		};
		if (width < 560) return [...main, action];
		return [...main, type, action];
	}, [width]);

	return (
		<div style={{ maxWidth: 840 }}>
			<Table
				rowKey='uid'
				columns={columns}
				dataSource={data}
				loading={isLoading}
			/>
		</div>
	);
}

export default TableView;
