/* eslint-disable import/order */
import { Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './styles/index.scss';

import { AlreadyLoggedIn, PersistLogin, RequireAuth } from 'components';
import { getUser } from 'redux/features/auth';

// Auth pages
import UserAuthPage from 'pages/UserAuth';
import AdminLoginPage from 'pages/AdminAuth';

// layout
import Layout from 'pages/Dashboard/Layout';

// general pages
import NotFound from 'pages/404';
import HomePageUser from 'pages/Dashboard/HomePageUser';
import SettingsPage from 'pages/Dashboard/SettingsPage';
import EarningsPage from 'pages/Dashboard/EarningsPage';
import MessagesPage from './pages/Dashboard/Messages';
import GeofencePage from 'pages/Dashboard/Geofence';
import ConfigureGeofencePage from 'pages/Dashboard/Geofence/Configure';

// users
import UsersDefaultPage from 'pages/Dashboard/UserDefault';
import UserInfoPage from 'pages/Dashboard/UserInfo';

// poxes
import PoxDefaultPage from 'pages/Dashboard/PoxDefault';
import PoxInfoPage from 'pages/Dashboard/PoxInfo';
import PoxNewPage from 'pages/Dashboard/PoxNew';
import PoxEditPage from 'pages/Dashboard/PoxEdit';
import PoxVerificationPage from 'pages/Dashboard/PoxVerification';
import SalesRegion from 'pages/Dashboard/SalesRegion';
import ChangePassword from 'pages/ChangePassword';

// admin pages
import HomePageAdmin from 'pages/Dashboard/HomePageAdmin';
import PoxAdminPage from 'pages/Dashboard/PoxAdmin';
import ProjectInfoPage from 'pages/Dashboard/ProjectInfo';

function MyApp() {
	const user = useSelector(getUser);

	return (
		<Routes>
			<Route element={<PersistLogin />}>
				<Route element={<RequireAuth />}>
					<Route path='/' element={<Layout />}>
						<Route
							index
							element={
								user?.isAdmin ? <HomePageAdmin /> : <HomePageUser />
							}
						/>
						<Route
							path='poxes'
							element={
								user?.isAdmin ? <PoxAdminPage /> : <PoxDefaultPage />
							}
						/>
						<Route path='poxes/new' element={<PoxNewPage />} />
						<Route path='poxes/owned' element={<PoxDefaultPage />} />
						<Route path='poxes/:id' element={<PoxInfoPage />} />
						<Route path='poxes/:id/edit' element={<PoxEditPage />} />
						<Route path='messages' element={<MessagesPage />} />
						<Route path='earnings' element={<EarningsPage />} />
						<Route path='settings'>
							<Route index element={<SettingsPage />} />
							<Route path='geofence' element={<GeofencePage />} />
							<Route
								path='geofence/configure/:id'
								element={<ConfigureGeofencePage />}
							/>
						</Route>
					</Route>
				</Route>

				<Route element={<RequireAuth requiresAdmin />}>
					<Route path='/' element={<Layout />}>
						<Route path='users' element={<UsersDefaultPage />} />
						<Route path='users/:id' element={<UserInfoPage />} />
						<Route
							path='poxes/:id/verify'
							element={<PoxVerificationPage />}
						/>
						<Route path='sales' element={<SalesRegion />} />
						<Route path='project-info' element={<ProjectInfoPage />} />
					</Route>

					<Route path='change-password' element={<ChangePassword />} />
				</Route>

				<Route element={<AlreadyLoggedIn />}>
					<Route path='/login' element={<UserAuthPage />} />
					<Route path='/admin' element={<AdminLoginPage />} />
				</Route>
			</Route>

			<Route path='*' element={<NotFound />} />
		</Routes>
	);
}

export default MyApp;
