import { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Table, PageHeader, Typography, Divider, Space } from 'antd';
import { useQuery } from '@tanstack/react-query';
import useAxiosPrivate from 'hooks/use-axios-private';
import useWindowDimensions from 'hooks/use-window-dimensions';
import useAntdTableSearch from 'hooks/use-antd-table-search';
import { withinThisWeek } from 'utils/timeDistance';
import useURLQuery from 'hooks/use-url-query';

export default function UserList() {
	const navigate = useNavigate();
	const axiosPrivate = useAxiosPrivate();
	const { width } = useWindowDimensions();
	const getColumnSearchProps = useAntdTableSearch();
	const URLquery = useURLQuery();
	const currentPage = URLquery.get('page') ? +URLquery.get('page') : 1;

	const { data: users, isLoading } = useQuery(['users'], async () => {
		const res = await axiosPrivate.get(`/users`);
		return res.data.users;
	});

	const active_users = useMemo(
		() => users?.filter((e) => withinThisWeek(e.metadata.lastSignInTime)),
		[users]
	);

	const columns = useMemo(() => {
		const main = [
			{
				title: 'User ID',
				dataIndex: 'id',
				key: 'id',
				defaultSortOrder: 'descend',
				sorter: (a, b) => +a.id - +b.id,
				render: (value, record) => (
					<Link to={`/users/${record.uid}`}>{value}</Link>
				),
			},
			{
				title: 'Email ID',
				dataIndex: 'email',
				key: 'email',
				...getColumnSearchProps('email'),
				render: (text) => (
					<Typography.Text
						ellipsis
						style={{ width: width <= 578 ? 135 : 'auto' }}
					>
						{text}
					</Typography.Text>
				),
			},
			{
				title: 'Poxes',
				dataIndex: 'poxes_stats',
				key: 'poxes_stats',
				render: (text) => <Typography.Text>{text.total}</Typography.Text>,
			},
		];

		const statusCol = {
			title: 'Status',
			dataIndex: 'disabled',
			key: 'disabled',
			render: (text) => {
				const status = text ? 'blocked' : 'active';
				return (
					<Typography.Text
						strong
						className={`status-${status}`}
						style={{ textTransform: 'uppercase', fontSize: '0.9em' }}
					>
						{status}
					</Typography.Text>
				);
			},
			filters: [
				{
					text: 'Active',
					value: false,
				},
				{
					text: 'Blocked',
					value: true,
				},
			],
			onFilter: (value, record) => record.disabled === value,
		};

		if (width <= 578) {
			return [...main, statusCol];
		}

		return [
			main[0],
			{
				title: 'Signup Date',
				dataIndex: 'metadata',
				key: 'metadata',
				...getColumnSearchProps('metadata'),
				defaultSortOrder: 'descend',
				sorter: (a, b) =>
					new Date(a.metadata.creationTime).getTime() -
					new Date(b.metadata.creationTime).getTime(),
				render: (obj) =>
					new Date(obj?.creationTime).toLocaleDateString('en-uk', {
						year: 'numeric',
						month: 'short',
						day: 'numeric',
					}),
			},
			...main.slice(1, 3),
			statusCol,
		];
	}, [width]);

	return (
		<div style={{ maxWidth: 960 }}>
			<PageHeader
				className='site-page-header'
				onBack={() => navigate(-1)}
				title='Users'
			/>

			<div
				style={{
					backgroundColor: 'white',
					padding: '12px 16px',
					borderRadius: 3,
					display: 'flex',
					alignItem: 'center',
					justifyContent: 'center',
					marginBottom: 24,
				}}
			>
				<Space direction='vertical' size={0} style={{ flexGrow: 1 }}>
					<Typography.Title level={5}>Registered Users</Typography.Title>
					<Typography.Title level={4}>{users?.length}</Typography.Title>
				</Space>

				<Divider type='vertical' style={{ height: 60 }} />

				<Space direction='vertical' size={0} style={{ flexGrow: 1 }}>
					<Typography.Title level={5}>Active Users</Typography.Title>
					<Typography.Title level={4}>
						{active_users?.length}
					</Typography.Title>
				</Space>
			</div>

			<Table
				rowKey='id'
				columns={columns}
				dataSource={users}
				loading={isLoading}
				pagination={{
					pageSize: 8,
					current: currentPage,
					onChange: (e) => navigate(`?page=${e}`),
				}}
			/>
		</div>
	);
}
