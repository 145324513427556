import PropTypes from 'prop-types';
import { Spin, Typography } from 'antd';

function PoxLoader({ title }) {
	return (
		<div
			style={{
				position: 'fixed',
				top: 0,
				left: 0,
				zIndex: 100,
				width: '100%',
				height: '100%',
				backgroundColor: 'rgba(0, 0, 0, 0.25)',
				display: 'grid',
				placeItems: 'center',
			}}
		>
			<div
				style={{
					width: 360,
					padding: 24,
					backgroundColor: 'white',
					borderRadius: 16,
					display: 'inherit',
					placeItems: 'center',
					gap: 8,
					textAlign: 'center',
				}}
			>
				<Typography.Title level={4}>{title}</Typography.Title>
				<Spin size='large' />
			</div>
		</div>
	);
}

PoxLoader.propTypes = {
	title: PropTypes.string.isRequired,
};

export default PoxLoader;
