import { Col, List, PageHeader, Row, Space, Typography } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import useAxiosPrivate from 'hooks/use-axios-private';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import GeofenceMap from './GeofenceMap';

function Geofence() {
	const navigate = useNavigate();
	const axiosPrivate = useAxiosPrivate();
	const queryClient = useQueryClient();
	const { isLoading, data, isFetched } = useQuery(['geofences'], async () => {
		const res = await axiosPrivate.get('/geofences');
		return res.data?.geofences;
	});

	const onSuccess = () => {
		queryClient.invalidateQueries(['geofences']);
	};

	const createFence = useMutation({
		mutationFn: async (payload) => {
			await axiosPrivate.post('/geofences', payload);
		},
		onSuccess,
	});

	const editFence = useMutation({
		mutationFn: async ({ id, payload }) => {
			await axiosPrivate.patch(`/geofences/${id}`, payload);
		},
		onSuccess,
	});

	const deleteFence = useMutation({
		mutationFn: async (id) => {
			await axiosPrivate.delete(`/geofences/${id}`);
		},
		onSuccess,
	});

	return (
		<div>
			<PageHeader
				className='site-page-header'
				onBack={() => navigate('/poxes')}
				title='Geofence'
			/>

			<div style={{ padding: '0 2%' }}>
				<div style={{ marginBottom: 20 }}>
					<Typography.Title level={5}>
						Mark a region on map as Geofence and get notified when any new
						pox is added in that Geofence region.
					</Typography.Title>

					<Typography.Text strong type='secondary'>
						Steps:
					</Typography.Text>
					<ol style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
						<li>Zoom and move to region you are interested in.</li>
						<li>
							Pick the rectangle and drag and adjust the size of it on
							the area you are interested in.
						</li>
						<li>Click on save to add the Geofence.</li>
					</ol>
				</div>
			</div>

			<Row
				style={{ padding: '0 4%' }}
				gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
			>
				<Col xs={24} md={12}>
					<div
						style={{
							backgroundColor: 'white',
							borderRadius: 10,
							height: 450,
							width: '100%',
						}}
					>
						{!isLoading && isFetched && (
							<GeofenceMap
								{...{
									fences: data,
									createFence: createFence.mutate,
									editFence: editFence.mutate,
									deleteFence: deleteFence.mutate,
								}}
							/>
						)}
					</div>
				</Col>
				<Col xs={24} md={12}>
					<div
						style={{
							backgroundColor: 'white',
							borderRadius: 10,
							minHeight: 450,
							width: '100%',
						}}
					>
						<List
							header={
								<Typography.Title
									style={{ paddingLeft: 10, margin: 0 }}
									level={5}
								>
									Geofence added:
								</Typography.Title>
							}
							itemLayout='horizontal'
							loading={isLoading}
							dataSource={data}
							renderItem={(item, idx) => (
								<List.Item>
									<div
										style={{
											width: '100%',
											display: 'flex',
											justifyContent: 'space-between',
											alignItems: 'center',
											padding: '0 10px',
										}}
									>
										<Space>
											<Typography.Text>
												{item?.name ?? `Geofence - ${idx + 1}`}
											</Typography.Text>
											<Typography.Text
												type='secondary'
												style={{ fontSize: '0.85em' }}
											>{`added on ${new Date(
												item?.createdAt
											).toLocaleDateString()}`}</Typography.Text>
										</Space>

										<Link
											to={`/settings/geofence/configure/${item.id}`}
											style={{ fontSize: '0.8em' }}
										>
											configure
										</Link>
									</div>
								</List.Item>
							)}
						/>
					</div>
				</Col>
			</Row>
		</div>
	);
}

export default Geofence;
