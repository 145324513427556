import { useState } from 'react';
import { Button, Modal, Radio, Space, Typography } from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { DeleteFilled } from '@ant-design/icons';
import useAxiosPrivate from 'hooks/use-axios-private';

function ConfigureGeofence() {
	const location = useLocation();
	const navigate = useNavigate();
	const axiosPrivate = useAxiosPrivate();
	const queryClient = useQueryClient();
	const { id } = useParams();
	const [formstate, setState] = useState({
		name: '',
		pox_type: '',
		color: '',
	});

	const onChange = (e) =>
		setState((prev) => ({ ...prev, [e.target.name]: e.target.value }));

	const close = () => navigate(-1);

	const { data } = useQuery(['geofence', { fence_id: id }], async () => {
		const res = await axiosPrivate.get(`/geofences/${id}`);
		setState({
			name: res.data?.geofence?.name,
			color: res.data?.geofence?.color,
			pox_type: res.data?.geofence?.pox_type,
		});
		return res.data?.geofence;
	});

	const onSuccess = () => {
		queryClient.resetQueries(['geofences']);
		queryClient.invalidateQueries(['geofence', { fence_id: id }]);
		close();
	};

	const editFence = useMutation({
		mutationFn: async (payload) => {
			await axiosPrivate.patch(`/geofences/${id}`, payload);
		},
		onSuccess,
	});

	const deleteFence = useMutation({
		mutationFn: async () => {
			await axiosPrivate.delete(`/geofences/${id}`);
		},
		onSuccess,
	});

	const onSave = () => {
		const payload = {};
		if (formstate.color !== data?.color) payload.color = formstate.color;
		if (formstate.name !== data?.name) payload.name = formstate.name;
		if (formstate.pox_type !== data?.pox_type)
			payload.pox_type = formstate.pox_type;
		editFence.mutate(payload);
	};

	return (
		<Modal
			open={location.pathname.includes('/settings/geofence/configure')}
			onCancel={close}
			centered
			title={
				<Typography.Title level={4} style={{ marginBottom: 0 }}>
					{data?.name}
				</Typography.Title>
			}
			footer={
				<div>
					<Space>
						<Button
							danger
							icon={<DeleteFilled style={{ color: 'red' }} />}
							loading={deleteFence.isLoading}
							onClick={deleteFence.mutate}
						/>
						<Button
							type='primary'
							disabled={
								formstate.name === data?.name &&
								formstate.color === data?.color &&
								formstate.pox_type === data?.pox_type
							}
							loading={editFence.isLoading}
							onClick={onSave}
						>
							Save
						</Button>
					</Space>
				</div>
			}
		>
			<Space direction='vertical' size={24} style={{ width: '100%' }}>
				<label htmlFor='name'>
					<span style={{ display: 'block', marginBottom: 4 }}>Name</span>
					<input
						type='text'
						id='name'
						name='name'
						value={formstate.name}
						onChange={onChange}
						style={{
							width: '100%',
							border: 'thin solid gray',
							borderRadius: 4,
							padding: '6px 8px',
							fontSize: '0.95em',
						}}
					/>
				</label>
				<div>
					<span style={{ display: 'block', marginBottom: 4 }}>
						Pox Type
					</span>
					<Radio.Group
						id='pox_type'
						name='pox_type'
						value={formstate.pox_type}
						onChange={onChange}
						style={{
							width: '100%',
							fontSize: '0.95em',
						}}
					>
						<Radio value='All'>All</Radio>
						<Radio value='Flat'>Flat</Radio>
						<Radio value='Plot'>Plot</Radio>
						<Radio value='Estab'>Estab</Radio>
					</Radio.Group>
				</div>
				<label htmlFor='color'>
					<span
						style={{
							display: 'block',
							marginBottom: 4,
						}}
					>
						Color
					</span>
					<div
						style={{
							borderRadius: 4,
							backgroundColor: formstate.color,
							display: 'inline-block',
						}}
					>
						<input
							type='color'
							id='color'
							name='color'
							value={formstate.color}
							onChange={onChange}
							style={{ cursor: 'pointer', opacity: 0 }}
						/>
					</div>
				</label>
			</Space>
		</Modal>
	);
}

export default ConfigureGeofence;
