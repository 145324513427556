import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// Styling components
import { Button, message } from 'antd';
import Icon from '@ant-design/icons';
// Loader component
import Loader from 'components/Loader';
// actions
import { logInWithGoogle, loginWithFacebook } from 'utils/firebase/auth';
// assets
import logo from './assets/logo.png';
import globe from './assets/globe.png';
import { ReactComponent as GoogleSvg } from './assets/Google.svg';
import { ReactComponent as FacebookSvg } from './assets/Facebook.svg';
import { ReactComponent as MapSvg } from './assets/map.svg';
import './assets/style.scss';

export default function GoogleOauth() {
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();

	const handleLogin = async (type) => {
		setLoading(true);
		try {
			switch (type) {
				case 'facebook':
					await loginWithFacebook();
					break;
				case 'google':
					await logInWithGoogle();
					break;
				default:
					await logInWithGoogle();
					break;
			}
			navigate(location?.state?.from || '/');
		} catch (error) {
			message.error('There was an error, please try again');
		} finally {
			setLoading(false);
		}
	};

	if (!loading)
		return (
			<div className='section-auth'>
				<div className='section-logo'>
					<img src={logo} alt='logo' />
				</div>

				<div className='section-forms'>
					<div className='view-img'>
						<img src={globe} alt='Globe Img' />
					</div>

					<div className='right'>
						<div className='header-text'>Welcome to UCPOX</div>

						<div className='login-btn'>
							<Button
								size='large'
								style={{ height: 58, borderRadius: 8 }}
								icon={
									<Icon
										component={GoogleSvg}
										style={{ fontSize: '1.5em' }}
									/>
								}
								onClick={() => handleLogin('google')}
							>
								Login with Google
							</Button>

							<div className='divider'>
								<span>OR</span>
							</div>

							<Button
								size='large'
								style={{ height: 58, borderRadius: 8 }}
								icon={
									<Icon
										component={FacebookSvg}
										style={{ fontSize: '1.5em' }}
									/>
								}
								onClick={() => handleLogin('facebook')}
							>
								Login with Facebook
							</Button>
						</div>

						<a href={process.env.REACT_APP_MAP_URL} className='map-link'>
							<MapSvg />
							Map Platform
						</a>
					</div>
				</div>
			</div>
		);

	return <Loader />;
}
