import Icon from '@ant-design/icons';
import PropTypes from 'prop-types';

const component = ({ active = false }) => (
	<svg
		width='24'
		height='24'
		viewBox='0 0 34 34'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<g clipPath='url(#clip0_1021_763)'>
			<path
				d='M15.5835 9.91666H18.4168V12.75H15.5835V9.91666ZM15.5835 15.5833H18.4168V24.0833H15.5835V15.5833ZM17.0002 2.83333C9.18016 2.83333 2.8335 9.18 2.8335 17C2.8335 24.82 9.18016 31.1667 17.0002 31.1667C24.8202 31.1667 31.1668 24.82 31.1668 17C31.1668 9.18 24.8202 2.83333 17.0002 2.83333ZM17.0002 28.3333C10.7527 28.3333 5.66683 23.2475 5.66683 17C5.66683 10.7525 10.7527 5.66666 17.0002 5.66666C23.2477 5.66666 28.3335 10.7525 28.3335 17C28.3335 23.2475 23.2477 28.3333 17.0002 28.3333Z'
				fill={active ? '#042954' : '#808080'}
			/>
		</g>
		<defs>
			<clipPath id='clip0_1021_763'>
				<rect width='34' height='34' fill='white' />
			</clipPath>
		</defs>
	</svg>
);

function CustomProjectInfoIcon(props) {
	const { active, style } = props;
	return (
		<Icon
			{...{
				style,
				component: () => component({ active }),
			}}
		/>
	);
}

CustomProjectInfoIcon.defaultProps = {
	active: false,
	style: {},
};

CustomProjectInfoIcon.propTypes = {
	active: PropTypes.bool,
	style: PropTypes.object,
};

export default CustomProjectInfoIcon;
