import { useState, useEffect, useMemo } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Layout, Menu, Avatar, Image, Typography } from 'antd';
import {
	CustomDashboardIcon,
	CustomPoxesIcon,
	CustomSettingsIcon,
	CustomUsersIcon,
	CustomMyPoxesIcon,
	CustomEarningsIcon,
	CustomGeofenceIcon,
	CustomProjectInfoIcon,
} from 'components/Icons';
import PropTypes from 'prop-types';
import logoFull from '../assets/logo.png';
import logoMin from '../assets/ucpox_ux.png';
import profImg from '../assets/profile.jpg';
import { ReactComponent as MapSvg } from '../assets/map.svg';
import '../assets/styles.scss';

const getMenuItems = new Map([
	[
		'all',
		[
			['1', '/', 'Dashboard', CustomDashboardIcon],
			['2', '/poxes', 'Poxes', CustomPoxesIcon],
		],
	],
	[
		true,
		[
			['4', '/users', 'Users', CustomUsersIcon],
			[
				'8',
				'/project-info?current=all',
				'Project Info',
				CustomProjectInfoIcon,
			],
			['6', '/settings?current=list', 'Settings', CustomSettingsIcon],
		],
	],
	[
		false,
		[
			['3', '/poxes/owned', 'My Poxes', CustomMyPoxesIcon],
			['7', '/settings/geofence', 'Geofence', CustomGeofenceIcon],
			['5', '/earnings', 'Earnings', CustomEarningsIcon],
		],
	],
]);

const menuItem = (selectedKey, collapsed, key, to, label, MIcon, children) => {
	const active = selectedKey[0] === key;
	return {
		key,
		style: {
			background: active ? 'rgba(23, 24, 92, 0.1)' : '',
		},
		icon: (
			<MIcon active={active} style={collapsed ? { marginLeft: -5 } : {}} />
		),
		label: to ? (
			<Link to={to}>
				<Typography.Text
					strong={active}
					style={{
						color: active ? '#17185C' : '#808080',
						fontSize: '1.2em',
					}}
				>
					{label}
				</Typography.Text>
			</Link>
		) : (
			<Typography.Text style={{ color: '#808080', fontSize: '1.2em' }}>
				{label}
			</Typography.Text>
		),
		children:
			children?.map((e) => {
				const childActive = selectedKey[0] === e.key;
				return {
					key: e.key,
					icon: (
						<e.icon
							active={childActive}
							style={collapsed ? { marginLeft: -5 } : {}}
						/>
					),
					label: (
						<Link to={e.url}>
							<Typography.Text
								strong={childActive}
								style={{
									color: childActive ? '#17185C' : '#808080',
									fontSize: '1.2em',
								}}
							>
								{e.label}
							</Typography.Text>
						</Link>
					),
				};
			}) ?? null,
	};
};

export default function Sidebar({ user, collapsed }) {
	const { id } = useParams();
	const location = useLocation();
	const [selectedKey, setSelectedKey] = useState([]);

	const navHelper = useMemo(
		() => ({
			'/': '1',
			'/poxes': '2',
			[`/poxes/${id}`]: '2',
			'/poxes/new': '2',
			[`/poxes/${id}/edit`]: '2',
			[`/poxes/${id}/verify`]: '2',
			'/poxes/owned': '3',
			'/users': '4',
			[`/users/${id}`]: '4',
			'/earnings': '5',
			'/settings': '6',
			'/settings/geofence': '7',
			'/sales': '1',
			'/messages': '1',
			'/project-info': '8',
		}),
		[id]
	);

	useEffect(() => {
		const { pathname } = location;
		setSelectedKey([navHelper[pathname]]);
	}, [location, id]);

	return (
		<Layout.Sider
			trigger={null}
			collapsible
			collapsed={collapsed}
			style={{ background: 'white' }}
			width='240'
		>
			<a className='logo' href={process.env.REACT_APP_MAP_URL}>
				<img src={collapsed ? logoMin : logoFull} alt='ucpox' />
			</a>

			<div className='user-avatar'>
				<Avatar
					size={collapsed ? 48 : 80}
					src={
						<Image
							src={user.photoURL || profImg}
							preview={false}
							style={{ width: '100%' }}
							referrerPolicy='no-referrer'
						/>
					}
				/>
				{!collapsed && (
					<Typography.Title
						level={5}
						style={{
							margin: 0,
							color: 'black',
							textAlign: 'center',
							fontSize: '20px',
						}}
					>
						{user.displayName}
					</Typography.Title>
				)}
			</div>
			<Menu
				mode='inline'
				selectedKeys={selectedKey}
				style={{ '--steps': user?.isAdmin ? '5' : '5' }}
				items={[
					...getMenuItems
						.get('all')
						.slice(0, 2)
						.map((item) => menuItem(selectedKey, collapsed, ...item)),
					...getMenuItems
						.get(user?.isAdmin)
						.map((item) => menuItem(selectedKey, collapsed, ...item)),
					{
						label: (
							<Typography.Title
								level={5}
								style={{ marginBottom: 0, color: '#808080' }}
							>
								Map Platform
							</Typography.Title>
						),
						icon: (
							<MapSvg
								style={
									collapsed
										? { marginLeft: -5, width: 24, height: 24 }
										: { width: 24, height: 24 }
								}
							/>
						),
						onClick: () => {
							window.location.href = process.env.REACT_APP_MAP_URL;
						},
						className: 'map-link',
					},
				]}
			/>
		</Layout.Sider>
	);
}

Sidebar.propTypes = {
	user: PropTypes.object.isRequired,
	collapsed: PropTypes.bool.isRequired,
};
