import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Avatar,
	Col,
	Image,
	List,
	PageHeader,
	Row,
	Space,
	Typography,
} from 'antd';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { v4 as uuid } from 'uuid';
import useWindowDimensions from 'hooks/use-window-dimensions';
import useAxiosPrivate from 'hooks/use-axios-private';
import useURLQuery from 'hooks/use-url-query';
import { useSelector } from 'react-redux';
import { getUser } from 'redux/features/auth';
import MessageBox from './MessageBox';
import './styles.scss';

function MessagesPage() {
	const navigate = useNavigate();
	const { width } = useWindowDimensions();
	const [room, setRoom] = useState(null);
	const axiosPrivate = useAxiosPrivate();
	const queryClient = useQueryClient();
	const query = useURLQuery();
	const user = useSelector(getUser);

	const { refetch: createRoom } = useQuery(
		['create-chat'],
		async () => {
			const contactid = query.get('to');
			const poxUrl = query.get('for');
			const initialMessage = {
				id: uuid(),
				author: user.uid,
				content: `Good day, I am interested in this Property ${poxUrl}`,
				content_type: 'text',
				created_at: Date.now(),
			};
			const res = await axiosPrivate.post(`chats`, {
				contactid,
				initialMessage,
			});
			if (!res.data?.message) {
				setRoom(res.data);
				queryClient.invalidateQueries('contacts');
			}
			return res.data;
		},
		{ enabled: false }
	);

	const { data, isLoading } = useQuery(
		['contacts', user.uid],
		async () => {
			const res = await axiosPrivate('chats/contacts');

			const contactid = query.get('to');
			if (
				contactid &&
				!res.data?.find(({ contact }) => contact.uid === contactid) &&
				contactid !== user.uid &&
				!user.isAdmin
			) {
				createRoom();
			}

			return res.data;
		},
		{ staleTime: 60 * 1000 }
	);

	const joinRoom = async ({ chatid, contact, unread }) => {
		const res = await axiosPrivate(`chats/${chatid}`);
		if (unread) {
			await axiosPrivate.patch(`chats/${chatid}`);
			queryClient.invalidateQueries('contacts');
		}
		setRoom({
			roomid: res.data?.room,
			messages: res.data?.messages,
			chatid,
			contact,
		});
	};

	return (
		<>
			<PageHeader
				className='site-page-header'
				onBack={() => (width <= 578 ? setRoom(null) : navigate(-1))}
				title='Messages'
			/>

			<Row style={{ flexGrow: 1, overflow: 'hidden' }}>
				<Col
					xs={24}
					md={8}
					lg={6}
					className='inbox'
					style={width <= 578 && room ? { left: '-100%' } : {}}
				>
					<List
						itemLayout='horizontal'
						header={
							<Typography.Title level={4} style={{ marginBottom: 0 }}>
								Inbox
							</Typography.Title>
						}
						dataSource={data}
						loading={isLoading}
						renderItem={(item) => (
							<List.Item
								style={{
									cursor: 'pointer',
									backgroundColor:
										room?.chatid === item?.chatid
											? 'rgba(23, 24, 92, 0.08)'
											: 'transparent',
								}}
								onClick={() => joinRoom(item)}
							>
								<Space size='small'>
									<Avatar
										size={30}
										style={{ border: 'thin solid #808080' }}
										src={
											<Image
												src={item?.contact?.photoURL}
												alt={item?.contact?.displayName}
												referrerPolicy='no-referrer'
												preview={false}
											/>
										}
									/>
									<Typography.Title
										level={5}
										style={{ marginBottom: 0 }}
									>
										{item?.contact?.displayName}
									</Typography.Title>
								</Space>

								{item?.unread && <span className='unread' />}
							</List.Item>
						)}
					/>
				</Col>

				<Col xs={0} md={1} />

				<Col xs={24} md={15} lg={17} className='message-box'>
					{room && <MessageBox {...{ room }} />}
				</Col>
			</Row>
		</>
	);
}

export default MessagesPage;
