export default function convertBytes(bytes) {
	if (bytes > 1000000) {
		return `${Math.ceil(bytes / 1000000).toLocaleString()} mb`;
	}

	if (bytes > 1000) {
		return `${Math.ceil(bytes / 1000).toLocaleString()} kb`;
	}

	return `${bytes} bytes`;
}
