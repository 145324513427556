import { Link } from 'react-router-dom';
import { Card, Col, PageHeader, Row, Space, Input } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Map } from 'components';
import './styles.scss';

const { Search } = Input;

export default function SalesRegion() {
	return (
		<div className='sales-container'>
			<Row>
				<Col span={24}>
					<div className='sales-top'>
						<Link to='/'>
							<ArrowLeftOutlined />
						</Link>

						<PageHeader className='site-page-header' title='Dashboard' />
					</div>

					<Card className='sales-card'>
						<div className='sales-card-top'>
							<h1 className='dashboard-heading2'>Sales by Region</h1>
							<Space>
								<Search placeholder='Search Region' enterButton />
							</Space>
						</div>

						<div className='map'>
							<Map
								position={{
									lat: 20.5937,
									lng: 78.9629,
								}}
							/>
						</div>
					</Card>
				</Col>
			</Row>
		</div>
	);
}
