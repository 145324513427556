function formatTime(time) {
	return time < 10 ? `0${time}` : time;
}

export function formatDay(date) {
	let dd = date.getDate();
	let MM = date.getMonth();
	let yyyy = date.getFullYear();
	dd = dd < 10 ? `0${dd}` : `${dd}`;
	MM = MM < 10 ? `0${MM}` : `${MM}`;
	yyyy = `${yyyy}`;
	const yyyyMMdd = yyyy + MM + dd;

	const today = new Date();
	let today_dd = today.getDate();
	let today_MM = today.getMonth();
	let today_yyyy = today.getFullYear();
	today_dd = today_dd < 10 ? `0${today_dd}` : `${today_dd}`;
	today_MM = today_MM < 10 ? `0${today_MM}` : `${today_MM}`;
	today_yyyy = `${today_yyyy}`;
	const today_yyyyMMdd = today_yyyy + today_MM + today_dd;

	if (+yyyyMMdd - +today_yyyyMMdd === 0) return 'Today';
	if (+yyyyMMdd - +today_yyyyMMdd === 1) return 'Yesterday';

	const options = {
		year: 'numeric',
		month: 'short',
		day: 'numeric',
	};
	return date.toLocaleDateString(undefined, options);
}

export default function chatTime(time) {
	const date = new Date(time);
	const hours = formatTime(date.getHours());
	const minutes = formatTime(date.getMinutes());
	const day = formatDay(date);

	return { day, at: `${hours}:${minutes}` };
}
