/* eslint-disable react/prop-types */
import './styles.scss';

function CustomDropdown({ children, close }) {
	return (
		<div className='custom-modal-wrapper'>
			<button
				type='button'
				aria-label='close modal'
				onClick={close}
				className='mask'
			/>
			<div className='child-wrapper'>{children}</div>
		</div>
	);
}

export default CustomDropdown;
