import {
	getStorage,
	ref,
	uploadBytesResumable,
	getDownloadURL,
	deleteObject,
} from 'firebase/storage';
import firebaseApp from 'config/firebase';
import { v4 } from 'uuid';
import { message } from 'antd';

export const storage = getStorage(firebaseApp);

export const customRequest = async ({
	file,
	onSuccess,
	onError,
	onProgress,
}) => {
	const name = v4();
	const storageRef = ref(storage, `/pox_images/${name}`);
	const uploadTask = uploadBytesResumable(storageRef, file);

	uploadTask.on(
		'state_changed',
		(snapshot) => {
			const percent = Math.round(
				(snapshot.bytesTransferred / snapshot.totalBytes) * 100
			);
			// update progress
			onProgress(percent);
		},
		(err) => {
			// eslint-disable-next-line no-console
			console.log('Error: ', err);
			const error = new Error('Some error');
			message.success(`There was an error`);
			onError({ error });
		},
		() => {
			// download url
			getDownloadURL(uploadTask.snapshot.ref).then((url) => {
				onSuccess({ pathname: `pox_images/${name}`, url });
				message.success(`${file.name} file uploaded successfully`);
			});
		}
	);
};

export const onRemove = (file) => {
	// cancel upload if still uploading
	if (file.status === 'uploading' || file.status === 'error') {
		return true;
	}

	const url = file.url || file.response?.url;
	const desertRef = ref(storage, url);
	deleteObject(desertRef)
		.then(() => true)
		.catch((error) => {
			// eslint-disable-next-line no-console
			console.log('Error:', error);
			return false;
		});

	return undefined;
};

export const uploadChatFile = async (
	{ file, onSuccess, onError, onProgress },
	chatid
) => {
	const name = v4();
	const ext = file.name.split('.').pop();
	const pathname = `/chats/${chatid}/${name}.${ext}`;
	const storageRef = ref(storage, pathname);
	const uploadTask = uploadBytesResumable(storageRef, file);

	uploadTask.on(
		'state_changed',
		(snapshot) => {
			const percent = Math.round(
				(snapshot.bytesTransferred / snapshot.totalBytes) * 100
			);
			// update progress
			onProgress(percent);
		},
		(err) => {
			// eslint-disable-next-line no-console
			console.log('Error: ', err);
			const error = new Error('Some error');
			message.success(`There was an error`);
			onError({ error });
		},
		() => {
			// download url
			getDownloadURL(uploadTask.snapshot.ref).then((url) => {
				onSuccess({ pathname, url });
				message.success(`${file.name} file uploaded successfully`);
			});
		}
	);
};
