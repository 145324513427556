function lifetimeLeft(date) {
	const lifetime = new Date(Date.now() - 1000 * 60 * 60 * 24 * 30).getTime();
	const poxTime = new Date(date).getTime();

	const daysLeft = Math.ceil((poxTime - lifetime) / (1000 * 60 * 60 * 24));

	return daysLeft > 0 ? daysLeft : 'Expiring Soon';
}

export default lifetimeLeft;
