import { useState, useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Table, PageHeader, Button, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import useWindowDimensions from 'hooks/use-window-dimensions';
import useAxiosPrivate from 'hooks/use-axios-private';
import useAntdTableSearch from 'hooks/use-antd-table-search';
import { EstabIcon, FlatIcon, PlotIcon } from 'components/Icons';
import useURLQuery from 'hooks/use-url-query';
import './styles.scss';

export default function PoxAdmin() {
	const location = useLocation();
	const navigate = useNavigate();
	const { width } = useWindowDimensions();
	const [isVerified, setIsVerified] = useState(false);
	const axiosPrivate = useAxiosPrivate();
	const getColumnSearchProps = useAntdTableSearch();
	const URLquery = useURLQuery();
	const currentPage = URLquery.get('page') ? +URLquery.get('page') : 1;

	const { data, isLoading } = useQuery(
		['poxes-data', { showBlocked: true, isVerified }],
		async () => {
			const res = await axiosPrivate.get(
				`/poxes?showBlocked=true&isVerified=${isVerified}`
			);
			return res.data.poxes;
		}
	);

	const columns = useMemo(() => {
		const main = [
			{
				title: 'Pox Id',
				dataIndex: 'id',
				...getColumnSearchProps('id'),
				defaultSortOrder: 'descend',
				sorter: (a, b) => +a.id - +b.id,
				render: (value) => <Link to={`/poxes/${value}`}>{value}</Link>,
			},
			{
				title: 'Pox Type',
				dataIndex: 'type',
				filters: [
					{
						text: <FlatIcon />,
						value: 'Flat',
					},
					{
						text: <PlotIcon />,
						value: 'Plot',
					},
					{
						text: <EstabIcon />,
						value: 'Estab',
					},
				],
				onFilter: (value, record) => record.type.indexOf(value) === 0,
				render: (text) =>
					text === 'Plot' ? (
						<PlotIcon />
					) : text === 'Flat' ? (
						<FlatIcon />
					) : (
						<EstabIcon />
					),
			},
			{
				title: width > 578 ? 'Date Published' : 'Date',
				dataIndex: 'createdAt',
				key: 'createdAt',
				...getColumnSearchProps('createdAt'),
				defaultSortOrder: 'descend',
				sorter: (a, b) =>
					new Date(a.createdAt).getTime() -
					new Date(b.createdAt).getTime(),
				render: (text) =>
					new Date(text).toLocaleDateString('en-uk', {
						year: 'numeric',
						month: 'short',
						day: 'numeric',
					}),
			},
			{
				title: 'Status',
				dataIndex: 'status',
				filters: [
					{
						text: 'Active',
						value: 'active',
					},
					{
						text: 'Inactive',
						value: 'inactive',
					},
					{
						text: 'Blocked',
						value: 'blocked',
					},
				],
				onFilter: (value, record) => record.status.indexOf(value) === 0,
				render: (text) => (
					<Typography.Text
						strong
						className={`status-${text}`}
						style={{ textTransform: 'uppercase', fontSize: '0.9em' }}
					>
						{text}
					</Typography.Text>
				),
			},
		];

		if (width > 578) {
			return [
				...main.slice(0, 2),
				{
					title: 'Publisher Name',
					dataIndex: ['publishedBy', 'displayName'],
				},
				...main.slice(2, main.length),
			];
		}

		return main;
	}, [width]);

	return (
		<div className='admin-poxes-table'>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<PageHeader
					className='site-page-header'
					onBack={() => navigate(-1)}
					title={
						location.pathname === '/poxes/owned'
							? 'My Poxes'
							: 'All Poxes'
					}
				/>
				{location.pathname === '/poxes' && (
					<Button
						type='primary'
						style={{ marginRight: '3%' }}
						icon={<PlusOutlined />}
						onClick={() => navigate('/poxes/new')}
					>
						Add Pox
					</Button>
				)}
			</div>

			<div className='tab-menu'>
				<button
					title='unverified poxes'
					type='button'
					className={isVerified ? '' : 'active'}
					onClick={() => setIsVerified(false)}
				>
					Unverified Poxes
				</button>
				<button
					title='verified poxes'
					type='button'
					className={isVerified ? 'active' : ''}
					onClick={() => setIsVerified(true)}
				>
					Verified Poxes
				</button>
			</div>

			<div>
				<Table
					rowKey='id'
					columns={columns}
					dataSource={data}
					loading={isLoading}
					pagination={{
						current: currentPage,
						onChange: (e) => navigate(`?page=${e}`),
					}}
				/>
			</div>
		</div>
	);
}
