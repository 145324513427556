import Icon from '@ant-design/icons';

const component = () => (
	<svg
		width='32'
		height='32'
		viewBox='0 0 32 32'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M8.57528 23.4246C9.5503 24.3996 10.7078 25.1731 11.9817 25.7007C13.2557 26.2284 14.6211 26.5 15.9999 26.5C17.3788 26.5 18.7442 26.2284 20.0181 25.7007C21.2921 25.1731 22.4496 24.3996 23.4246 23.4246C24.3996 22.4496 25.1731 21.2921 25.7007 20.0181C26.2284 18.7442 26.5 17.3788 26.5 15.9999C26.5 14.6211 26.2284 13.2557 25.7007 11.9817C25.1731 10.7078 24.3996 9.5503 23.4246 8.57528C21.4555 6.60613 18.7847 5.49988 15.9999 5.49988C13.2152 5.49988 10.5444 6.60613 8.57528 8.57528C6.60613 10.5444 5.49988 13.2152 5.49988 15.9999C5.49988 18.7847 6.60613 21.4555 8.57528 23.4246ZM11.1081 22.5414C12.6811 23.7187 14.6257 24.2895 16.5854 24.1493C18.5451 24.009 20.3886 23.1671 21.7778 21.7778C23.1671 20.3886 24.009 18.5451 24.1493 16.5854C24.2895 14.6257 23.7187 12.6811 22.5414 11.1081L19.2993 14.3503C19.0792 14.5628 18.7845 14.6804 18.4786 14.6777C18.1727 14.6751 17.8801 14.5524 17.6638 14.3361C17.4475 14.1198 17.3248 13.8271 17.3222 13.5212C17.3195 13.2153 17.4371 12.9206 17.6496 12.7006L20.8906 9.45844C19.3173 8.28433 17.3738 7.71604 15.4158 7.85759C13.4578 7.99915 11.6162 8.84107 10.2282 10.2293C8.84012 11.6175 7.99839 13.4591 7.85704 15.4171C7.71568 17.3752 8.28417 19.3186 9.45844 20.8918L12.7006 17.6496C12.9206 17.4371 13.2153 17.3195 13.5212 17.3222C13.8271 17.3248 14.1198 17.4475 14.3361 17.6638C14.5524 17.8801 14.6751 18.1727 14.6777 18.4786C14.6804 18.7845 14.5628 19.0792 14.3503 19.2993L11.1081 22.5414Z'
			fill='#4274C4'
		/>
		<rect width='32' height='32' rx='4' fill='#042954' fillOpacity='0.08' />
	</svg>
);

function CustomUnblockIcon(props) {
	return <Icon {...{ props, component }} />;
}

export default CustomUnblockIcon;
