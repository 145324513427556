import { Link } from 'react-router-dom';
import { useQueries } from '@tanstack/react-query';
import { Col, Row, Space, Typography, PageHeader, Spin } from 'antd';
import useAxiosPrivate from 'hooks/use-axios-private';
import {
	withinThisMonth,
	withinThisWeek,
	withinToday,
} from 'utils/timeDistance';
import SalesSection from './SalesSection';
import './styles.scss';

const time_labels = [
	{ label: 'today', fn: withinToday },
	{ label: 'this week', fn: withinThisWeek },
	{ label: 'this month', fn: withinThisMonth },
];

export default function HomePageAdmin() {
	const axiosPrivate = useAxiosPrivate();

	const [
		{ data: users, isLoading: usersIsLoading },
		{ data: poxesStats, isLoading: poxesStatsIsLoading },
		{ data: poxesData, isLoading: poxesDataIsLoading },
	] = useQueries({
		queries: [
			{
				queryKey: ['users'],
				queryFn: async () => {
					const res = await axiosPrivate('/users');
					return res.data.users;
				},
			},
			{
				queryKey: ['poxes-stats'],
				queryFn: async () => {
					const res = await axiosPrivate('/poxes/stats');
					return res.data.stats;
				},
			},
			{
				queryKey: ['poxes-data', { showBlocked: true }],
				queryFn: async () => {
					const res = await axiosPrivate('/poxes?showBlocked=true');
					return res.data.poxes;
				},
			},
		],
	});

	return (
		<div className='home-page-admin'>
			<PageHeader className='site-page-header' title='Dashboard' />

			<Row gutter={[24, 24]}>
				<Col xs={24} md={9} xl={8}>
					<Space direction='vertical' className='poxes-col'>
						<Typography.Title level={5}>
							Total Active Poxes
						</Typography.Title>

						<div
							className='stats-wrapper'
							style={
								poxesStatsIsLoading ? { justifyContent: 'center' } : {}
							}
						>
							{poxesStats &&
								['flat', 'plot', 'estab'].map((label) => (
									<Link to='/' key={label} className='stats-card'>
										<Typography.Title level={3}>
											{poxesStats?.[`${label}_active`]}
										</Typography.Title>
										<Typography.Text>{label}</Typography.Text>
									</Link>
								))}
							{poxesStatsIsLoading && <Spin spinning size='large' />}
						</div>
					</Space>
				</Col>

				<Col xs={24} md={9} xl={{ span: 8, offset: 2 }}>
					<Space direction='vertical' className='poxes-col'>
						<Typography.Title level={5}>
							Total Inactive Poxes
						</Typography.Title>

						<div
							className='stats-wrapper'
							style={
								poxesStatsIsLoading ? { justifyContent: 'center' } : {}
							}
						>
							{poxesStats &&
								['flat', 'plot', 'estab'].map((label) => (
									<Link to='/' key={label} className='stats-card'>
										<Typography.Title level={3}>
											{poxesStats?.[`${label}_inactive`]}
										</Typography.Title>
										<Typography.Text>{label}</Typography.Text>
									</Link>
								))}
							{poxesStatsIsLoading && <Spin spinning size='large' />}
						</div>
					</Space>
				</Col>

				<Col xs={24} md={6} xl={{ span: 4, offset: 2 }}>
					<Space direction='vertical' align='center' className='poxes-col'>
						<Typography.Title level={5}>Blocked Poxes</Typography.Title>

						{users && (
							<Typography.Title level={2}>
								{poxesStats?.total_blocked?.toLocaleString()}
							</Typography.Title>
						)}

						{poxesStatsIsLoading && <Spin spinning />}
					</Space>
				</Col>

				<Col xs={24} md={9} xl={8}>
					<Space direction='vertical' className='poxes-col'>
						<Typography.Title level={5}>Poxes Created</Typography.Title>

						<div
							className='stats-wrapper'
							style={
								poxesDataIsLoading ? { justifyContent: 'center' } : {}
							}
						>
							{poxesData &&
								time_labels.map(({ label, fn }) => (
									<Link to='/' key={label} className='stats-card'>
										<Typography.Title level={3}>
											{
												poxesData?.filter((e_pox) =>
													fn(e_pox.createdAt)
												)?.length
											}
										</Typography.Title>
										<Typography.Text style={{ whiteSpace: 'nowrap' }}>
											{label}
										</Typography.Text>
									</Link>
								))}
							{poxesDataIsLoading && <Spin spinning size='large' />}
						</div>
					</Space>
				</Col>

				<Col xs={24} md={9} xl={{ span: 8, offset: 2 }}>
					<Space direction='vertical' className='poxes-col'>
						<Typography.Title level={5}>Poxes Sold Out</Typography.Title>

						<div
							className='stats-wrapper'
							style={
								poxesDataIsLoading ? { justifyContent: 'center' } : {}
							}
						>
							{poxesData &&
								time_labels.map(({ label, fn }) => (
									<Link to='/' key={label} className='stats-card'>
										<Typography.Title level={3}>
											{
												poxesData?.filter(
													(e_pox) =>
														e_pox.inactive_reason ===
															'sold_out' &&
														fn(e_pox.inactiveFrom)
												)?.length
											}
										</Typography.Title>
										<Typography.Text style={{ whiteSpace: 'nowrap' }}>
											{label}
										</Typography.Text>
									</Link>
								))}
							{poxesDataIsLoading && <Spin spinning size='large' />}
						</div>
					</Space>
				</Col>

				<Col xs={24} md={6} xl={{ span: 4, offset: 2 }}>
					<Space direction='vertical' align='center' className='poxes-col'>
						<Typography.Title level={5}>Total Poxes</Typography.Title>

						{users && (
							<Typography.Title level={2}>
								{poxesStats?.total?.toLocaleString()}
							</Typography.Title>
						)}

						{poxesStatsIsLoading && <Spin spinning />}
					</Space>
				</Col>

				<Col xs={24} md={9} xl={8}>
					<Space direction='vertical' className='poxes-col'>
						<Typography.Title level={5}>Sign Up</Typography.Title>

						<div
							className='stats-wrapper'
							style={usersIsLoading ? { justifyContent: 'center' } : {}}
						>
							{users &&
								time_labels.map(({ label, fn }) => (
									<Link to='/' key={label} className='stats-card'>
										<Typography.Title level={3}>
											{
												users?.filter((e_user) =>
													fn(e_user?.metadata?.creationTime)
												)?.length
											}
										</Typography.Title>
										<Typography.Text style={{ whiteSpace: 'nowrap' }}>
											{label}
										</Typography.Text>
									</Link>
								))}
							{usersIsLoading && <Spin spinning size='large' />}
						</div>
					</Space>
				</Col>

				<Col xs={24} md={6} xl={{ span: 4, offset: 2 }}>
					<Space direction='vertical' align='center' className='poxes-col'>
						<Typography.Title level={5}>Total Sign Up</Typography.Title>

						{users && (
							<Typography.Title level={2}>
								{users.length.toLocaleString()}
							</Typography.Title>
						)}

						{usersIsLoading && <Spin spinning />}
					</Space>
				</Col>

				<SalesSection />
			</Row>
		</div>
	);
}
