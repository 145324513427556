import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import {
	Row,
	Col,
	Space,
	Spin,
	Table,
	Typography,
	Dropdown,
	Button,
	Menu,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Map } from 'components';
import useAxiosPrivate from 'hooks/use-axios-private';
import useWindowDimensions from 'hooks/use-window-dimensions';
import SalesChart from './SalesChart';

const months = [
	'January',
	'Feburary',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

function SalesSection() {
	const axiosPrivate = useAxiosPrivate();
	const { width } = useWindowDimensions();

	const { data: poxes = [], isLoading } = useQuery(
		['poxes-data'],
		async () => {
			const res = await axiosPrivate('/poxes');
			return res.data.poxes;
		}
	);

	const columns = useMemo(() => {
		const extra =
			width <= 578
				? []
				: [
						{
							title: (
								<Typography.Title level={5}>Price</Typography.Title>
							),
							render: () => '$100,000',
						},
						{
							title: (
								<Typography.Title level={5}>Status</Typography.Title>
							),
							render: () => 'Pending',
						},
				  ];

		return [
			{
				title: <Typography.Title level={5}>Property name</Typography.Title>,
				dataIndex: 'name',
			},
			{
				title: <Typography.Title level={5}>Property type</Typography.Title>,
				dataIndex: 'type',
			},
			{
				title: <Typography.Title level={5}>Customer name</Typography.Title>,
				dataIndex: 'publishedBy',
				render: (text) => text.displayName,
			},
			{
				title: <Typography.Title level={5}>Sale type</Typography.Title>,
				dataIndex: 'sale_type',
			},
			...extra,
		];
	}, [width]);

	const menu = useMemo(
		() => (
			<Menu
				items={months.map((month, idx) => ({ label: month, key: idx }))}
			/>
		),
		[]
	);

	return (
		<>
			<Col xs={24} md={12}>
				<div className='sales-container'>
					<div className='header'>
						<Typography.Title level={5} style={{ margin: 0 }}>
							Total Sales {`$${2000}`}
						</Typography.Title>

						<Dropdown overlay={menu} trigger={['click']}>
							<Button
								type='dashed'
								size='small'
								style={{ borderRadius: 16 }}
							>
								<Space>
									Month
									<DownOutlined />
								</Space>
							</Button>
						</Dropdown>
					</div>

					<div className='content-area'>
						{isLoading ? (
							<Spin spinning size='large' />
						) : (
							<Row gutter={16}>
								<Col span={12}>
									<SalesChart />
								</Col>
								<Col span={12}>
									<Space size={[8, 16]} wrap style={{ width: '100%' }}>
										<div className='pox-info flat'>
											<Typography.Title level={5}>
												Flat
											</Typography.Title>
											<Typography.Text>$1,100</Typography.Text>
										</div>
										<div className='pox-info plot'>
											<Typography.Title level={5}>
												Plot
											</Typography.Title>
											<Typography.Text>$2,900</Typography.Text>
										</div>
										<div className='pox-info estab'>
											<Typography.Title level={5}>
												Estab
											</Typography.Title>
											<Typography.Text>$500</Typography.Text>
										</div>
									</Space>
								</Col>
							</Row>
						)}
					</div>
				</div>
			</Col>

			<Col xs={24} md={12}>
				<Link to='sales' className='sales-container'>
					<Typography.Title level={5}>Sales by Region</Typography.Title>

					<div className='content-area'>
						{isLoading ? (
							<Spin spinning size='large' />
						) : (
							poxes.length > 0 && (
								<Map positions={poxes.map((pox) => pox.position)} />
							)
						)}
					</div>
				</Link>
			</Col>

			<Col xs={24}>
				<Space
					direction='vertical'
					style={{ background: 'white', display: 'flex', padding: 8 }}
				>
					<Typography.Title level={5}>Sales Region</Typography.Title>

					<Table
						rowKey='id'
						columns={columns}
						dataSource={poxes}
						loading={isLoading}
						pagination={{ pageSize: 8 }}
					/>
				</Space>
			</Col>
		</>
	);
}

export default SalesSection;
