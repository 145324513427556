import loader from './img/805.gif';

export default function Loader() {
	return (
		<div
			style={{
				minHeight: '100%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<img src={loader} alt='loader' />
		</div>
	);
}
