import { PageHeader } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import useURLQuery from 'hooks/use-url-query';
import TableView from './TableView';
import FormView from './FormView';

const tabitems = [
	{ id: 'list', title: 'admins list', label: 'Admin List' },
	{ id: 'add_new', title: 'add new admin', label: 'Add Admin' },
];

export default function Settings() {
	const query = useURLQuery();
	const navigate = useNavigate();

	return (
		<div className='settings-page'>
			<PageHeader
				title='Settings'
				className='site-page-header'
				onBack={() => navigate(-1)}
			/>

			<div className='tab-menu'>
				{tabitems.map((item) => (
					<Link
						key={item.id}
						to={`/settings?current=${item.id}`}
						style={{ color: 'inherit' }}
					>
						<button
							title={item.title}
							type='button'
							className={
								query.get('current') === item.id ? 'active' : ''
							}
						>
							{item.id === 'add_new' && query.get('id')
								? 'Edit'
								: item.label}
						</button>
					</Link>
				))}
			</div>

			{query.get('current') === 'list' && <TableView />}
			{query.get('current') === 'add_new' && <FormView />}
		</div>
	);
}
