import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, message, Select } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosPrivate from 'hooks/use-axios-private';
import useURLQuery from 'hooks/use-url-query';

const category_options = [
	{ label: 'Super Admin', value: 'super_admin', disabled: true },
	{ label: 'Admin', value: 'admin' },
];

function FormView() {
	const [form] = Form.useForm();
	const axiosPrivate = useAxiosPrivate();
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const URLQuery = useURLQuery();
	const id = URLQuery.get('id');

	const clear = () => {
		form.resetFields();
	};

	const onSuccess = (result) => {
		message.success(result.message);
		queryClient.invalidateQueries(['admins']);
		navigate(`/settings?current=list`);
	};

	const onError = (error) => {
		message.error(error?.response?.data.message || error.message);
	};

	const save_info = useMutation({
		mutationFn: async (payload) => {
			const res = id
				? await axiosPrivate.patch(`/users/admin/${id}`, payload)
				: await axiosPrivate.post(`/users/admin`, payload);
			return res.data;
		},
		onSuccess,
		onError,
	});

	const finish = (values) => {
		const bodyData = { ...values };
		save_info.mutate(bodyData);
	};

	return (
		<div style={{ maxWidth: 640 }}>
			<Form
				name='new_admin'
				form={form}
				onFinish={finish}
				// layout='vertical'
				labelCol={{ span: 4 }}
				wrapperCol={{ span: 20 }}
				style={{ padding: '0 2%' }}
			>
				<Form.Item
					name='displayName'
					label='Admin Name'
					rules={[
						{
							required: true,
							message: 'Admin Name is required',
						},
					]}
				>
					<Input placeholder='Admin/Display Name...' />
				</Form.Item>

				<Form.Item
					name='admin_type'
					label='Admin Type'
					rules={[
						{
							required: true,
							message: 'Pick an Admin Type',
						},
					]}
				>
					<Select placeholder='Admin Type...'>
						{category_options.map((option) => (
							<Select.Option
								key={option.value}
								value={option.value}
								disabled={option.disabled}
							>
								{option.label}
							</Select.Option>
						))}
					</Select>
				</Form.Item>

				<Form.Item
					name='email'
					label='Email ID'
					rules={[
						{
							required: true,
							message: 'Email ID is required',
						},
					]}
				>
					<Input type='email' placeholder='Email ID...' />
				</Form.Item>

				<Form.Item
					name='password'
					label='Password'
					rules={[
						{
							required: true,
							message: 'Password is required',
						},
					]}
				>
					<Input.Password placeholder='Password...' />
				</Form.Item>

				<div
					style={{
						display: 'flex',
						justifyContent: 'flex-end',
						gap: 16,
					}}
				>
					<Button
						type='default'
						size='medium'
						onClick={clear}
						style={{ paddingRight: '2.5em', paddingLeft: '2.5em' }}
					>
						Clear all
					</Button>
					<Button
						type='primary'
						htmlType='submit'
						size='medium'
						loading={save_info.isLoading}
						style={{ paddingRight: '2.5em', paddingLeft: '2.5em' }}
					>
						{id ? 'Update' : 'Save'}
					</Button>
				</div>
			</Form>
		</div>
	);
}

export default FormView;
