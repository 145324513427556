import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Button, message, Popconfirm, Space, Table, Typography } from 'antd';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { DeleteOutlined } from '@ant-design/icons';
import useAxiosPrivate from 'hooks/use-axios-private';
import useWindowDimensions from 'hooks/use-window-dimensions';

function TableView() {
	const axiosPrivate = useAxiosPrivate();
	const { width } = useWindowDimensions();
	const queryClient = useQueryClient();

	const { isLoading, data } = useQuery(
		['project-info'],
		async () => {
			const res = await axiosPrivate(`/project-info`);
			return res.data?.project_info;
		},
		{
			onError: (error) => {
				message.error(error?.response?.data.message || error.message);
			},
		}
	);

	const delete_info = useMutation({
		mutationFn: async (id) => {
			const res = await axiosPrivate.delete(`/project-info/${id}`);
			return res.data;
		},
		onSuccess: (result) => {
			message.success(result.message);
			queryClient.invalidateQueries(['project-info']);
		},
		onError: (error) => {
			message.error(error?.response?.data.message || error.message);
		},
	});

	const columns = useMemo(() => {
		const main = [
			{ title: 'Title', key: 'title', dataIndex: 'title' },
			{ title: 'Category', key: 'category', dataIndex: 'category' },
		];
		const location_section = {
			title: 'Location',
			key: 'location',
			dataIndex: 'location',
			render: (text) => (
				<Typography.Text ellipsis style={{ maxWidth: 250 }}>
					{text}
				</Typography.Text>
			),
		};
		const action = {
			title: 'Action',
			render: (_text, record) => (
				<Space size='small'>
					<Link to={`/project-info?current=add_new&id=${record._id}`}>
						Edit
					</Link>
					<Popconfirm
						placement='topLeft'
						title='Delete Project Info'
						description='Are you sure you want to delete this?'
						onConfirm={() => delete_info.mutate(record._id)}
						okText='Yes'
						cancelText='No'
					>
						<Button
							type='text'
							danger
							icon={<DeleteOutlined />}
							style={{ padding: 0 }}
						/>
					</Popconfirm>
				</Space>
			),
		};
		if (width < 560) return [...main, action];
		return [location_section, ...main, action];
	}, [width]);

	return (
		<div style={{ maxWidth: 840 }}>
			<Table
				rowKey='_id'
				columns={columns}
				dataSource={data}
				loading={isLoading}
			/>
		</div>
	);
}

export default TableView;
