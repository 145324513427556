import { createElement } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
	Layout,
	Badge,
	Space,
	Button,
	Tooltip,
	Dropdown,
	Typography,
	Avatar,
	Image,
} from 'antd';
import {
	MenuUnfoldOutlined,
	MenuFoldOutlined,
	BellOutlined,
	MailOutlined,
	LogoutOutlined,
} from '@ant-design/icons';
import { CustomAccSettingsIcon } from 'components/Icons';
import { logOutSuccess } from 'redux/features/auth';
import { logOut } from 'utils/firebase/auth';
import CustomPopUps from 'utils/pop-ups';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import useToggle from 'hooks/use-toggle';
import useAxiosPrivate from 'hooks/use-axios-private';
import logoFull from '../assets/logo.png';
import profImg from '../assets/profile.jpg';
import NotificationsDropdown from './NotificationsDropdown';

export default function Header({ user, collapsed, toggle, width }) {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const axiosPrivate = useAxiosPrivate();
	const [isOpen, toggleIsOpen] = useToggle(false);
	const { featureNotReady } = CustomPopUps;

	// logout handler
	const logoutHandler = async () => {
		try {
			await logOut();
			dispatch(logOutSuccess());
			navigate('/login', { state: { from: location } });
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error);
		}
	};

	// unread messages
	const { data: unread_messages } = useQuery(
		['unread-messages', { uid: user.uid }],
		async () => {
			const res = await axiosPrivate('chats/unread');
			return res.data;
		},
		{ staleTime: 60 * 1000 }
	);

	// unread notifications
	const { data: unread_notifications } = useQuery(
		['unread-notifications', { uid: user.uid }],
		async () => {
			const res = await axiosPrivate('notifications/unread');
			return res.data;
		},
		{ staleTime: 60 * 1000 }
	);

	return (
		<Layout.Header
			className='site-layout-background'
			style={{
				padding: '0 16px 0 0',
				display: 'flex',
				justifyContent: 'space-between',
			}}
		>
			{width < 578 ? (
				<a className='logo' href={process.env.REACT_APP_MAP_URL}>
					<img src={logoFull} alt='ucpox' />
				</a>
			) : (
				createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
					className: 'trigger',
					onClick: toggle,
				})
			)}

			<Space size={16}>
				{!user.isAdmin && (
					<>
						<Tooltip title='Messages'>
							<Badge count={unread_messages}>
								<Button
									shape='circle'
									icon={<MailOutlined />}
									onClick={() => navigate('/messages')}
								/>
							</Badge>
						</Tooltip>
						<div>
							<Tooltip title='Notifications'>
								<Badge count={unread_notifications}>
									<Button
										shape='circle'
										icon={<BellOutlined />}
										onClick={toggleIsOpen}
									/>
								</Badge>
							</Tooltip>

							{isOpen && (
								<NotificationsDropdown
									close={() => toggleIsOpen(false)}
								/>
							)}
						</div>
					</>
				)}

				<Dropdown
					trigger={['click']}
					placement='bottomRight'
					style={{ display: 'flex', alignItems: 'center' }}
					menu={{
						className: 'custom-menu',
						items: [
							{
								key: '3',
								icon: <CustomAccSettingsIcon />,
								label: (
									<Typography.Text
										style={{
											fontSize: '1.1em',
											paddingLeft: 10,
										}}
										onClick={() =>
											user?.isAdmin
												? navigate('/change-password')
												: {}
										}
									>
										{user?.isAdmin
											? 'Change Password'
											: 'Account Settings'}
									</Typography.Text>
								),
								onClick: !user.isAdmin && featureNotReady,
							},
							{
								key: '4',
								icon: <LogoutOutlined style={{ fontSize: '1.35em' }} />,
								label: (
									<Typography.Text
										style={{
											fontSize: '1.1em',
											paddingLeft: 4,
										}}
									>
										Log out
									</Typography.Text>
								),
								onClick: logoutHandler,
							},
						],
					}}
				>
					{width > 578 ? (
						<Button
							type='link'
							style={{
								height: '100%',
								display: 'flex',
								alignItems: 'center',
								gap: 10,
							}}
						>
							<Avatar
								size={48}
								src={
									<Image
										src={user.photoURL || profImg}
										preview={false}
										style={{ width: '100%' }}
										referrerPolicy='no-referrer'
									/>
								}
							/>
							<Space direction='vertical' size={0} align='start'>
								<Typography.Text>{user.displayName}</Typography.Text>
								<Typography.Text style={{ fontSize: '0.85em' }}>
									{user.email}
								</Typography.Text>
							</Space>
						</Button>
					) : (
						<Button
							shape='circle'
							style={{
								height: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								padding: 0,
							}}
						>
							<Avatar
								size={32}
								src={
									<Image
										src={user.photoURL || profImg}
										preview={false}
										style={{ width: '100%' }}
										referrerPolicy='no-referrer'
									/>
								}
							/>
						</Button>
					)}
				</Dropdown>
			</Space>
		</Layout.Header>
	);
}

Header.propTypes = {
	user: PropTypes.object.isRequired,
	collapsed: PropTypes.bool.isRequired,
	toggle: PropTypes.func.isRequired,
	width: PropTypes.number.isRequired,
};
