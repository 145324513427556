import { useEffect, useState } from 'react';
import { Avatar, List, Input, Modal, Image, message, Button } from 'antd';
import { Link } from 'react-router-dom';
import useAxiosPrivate from 'hooks/use-axios-private';
import PropTypes, { string } from 'prop-types';

function AssignUsersModal({ setUser, user, visible, closeModal }) {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [modfiedData, setModifiedData] = useState([]);
	const axiosPrivate = useAxiosPrivate();

	const handleSearch = (value) => {
		setModifiedData(
			data.filter((e) => {
				if (e.uid === value) return true;

				if (e.displayName.toUpperCase().indexOf(value.toUpperCase()) > -1)
					return true;
				return false;
			})
		);
	};

	const handleClose = () => {
		closeModal();
	};

	const loadData = () => {
		setLoading(true);
		axiosPrivate(`/users`)
			.then(({ data: { users } }) => {
				setData(users);
				setModifiedData(users);
			})
			.catch((err) => {
				message.error(err?.response?.data?.message || err?.message);
			})
			.finally(() => setLoading(false));
	};

	useEffect(() => {
		loadData();
	}, []);

	return (
		<Modal
			title='Assign to User'
			open={visible}
			onOk={handleClose}
			onCancel={handleClose}
		>
			<Input.Search
				onSearch={handleSearch}
				placeholder='Search for user by id or name'
				size='large'
			/>

			<div
				id='scrollableDiv'
				style={{
					height: 400,
					overflow: 'auto',
					padding: '0 16px',
					border: '1px solid rgba(140, 140, 140, 0.35)',
				}}
			>
				<List
					loading={loading}
					dataSource={modfiedData}
					renderItem={(item) => (
						<List.Item
							key={item.uid}
							style={
								user?.uid === item.uid
									? { backgroundColor: '#40a9ff33' }
									: {}
							}
						>
							<List.Item.Meta
								avatar={
									<Avatar
										src={
											<Image
												src={item.photoURL}
												preview={false}
												style={{ width: '100%' }}
												referrerPolicy='no-referrer'
											/>
										}
									/>
								}
								title={
									<Link to={`/users/${item.uid}`}>
										{item.displayName}
									</Link>
								}
								description={item.email}
							/>
							<Button
								type='text'
								style={{
									color: user?.uid === item.uid ? 'black' : '#40a9ff',
								}}
								onClick={() => setUser(item)}
							>
								{user?.uid === item.uid ? 'Selected' : 'Select'}
							</Button>
						</List.Item>
					)}
				/>
			</div>
		</Modal>
	);
}

AssignUsersModal.defaultProps = {
	user: null,
};

AssignUsersModal.propTypes = {
	setUser: PropTypes.func.isRequired,
	user: PropTypes.objectOf({ uid: string }),
	visible: PropTypes.bool.isRequired,
	closeModal: PropTypes.func.isRequired,
};

export default AssignUsersModal;
