import { Modal, Radio, Space, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useState } from 'react';

function StatusUpdateModal({ open, close, mutation, data, isAdmin }) {
	const [status, setStatus] = useState(data.status);
	const [inactiveReason, setInactiveReason] = useState(data.inactive_reason);
	const handleSubmit = () => {
		if (status !== data.status) {
			if (status === 'inactive')
				mutation.mutate({ status, inactive_reason: inactiveReason });
			else mutation.mutate({ status });
		}
	};

	return (
		<Modal
			title={<Typography.Text strong>Update Pox Status</Typography.Text>}
			open={open}
			onOk={handleSubmit}
			onCancel={close}
			okText='Update Status'
			confirmLoading={mutation.isLoading}
			okButtonProps={{ disabled: status === data.status }}
			centered
			destroyOnClose
		>
			<Radio.Group
				defaultValue={data.status}
				onChange={(e) => setStatus(e.target.value)}
				value={status}
				style={{ display: 'block' }}
			>
				<Radio value='active'>Active</Radio>
				<Radio value='inactive'>Inactive</Radio>
				{isAdmin && <Radio value='blocked'>Blocked</Radio>}
			</Radio.Group>
			{status === 'inactive' && (
				<Space direction='vertical' style={{ marginTop: 16 }}>
					<Typography.Text type='secondary'>
						Select the reason for Inactive
					</Typography.Text>
					<Radio.Group
						defaultValue={data.inactive_reason}
						onChange={(e) => setInactiveReason(e.target.value)}
						value={inactiveReason}
					>
						<Radio value='sold_out'>Sold Out</Radio>
						<Radio value='temporarily_unavailable'>
							Temporarily Unavailable
						</Radio>
					</Radio.Group>
				</Space>
			)}
		</Modal>
	);
}

StatusUpdateModal.propTypes = {
	open: PropTypes.bool.isRequired,
	close: PropTypes.func.isRequired,
	mutation: PropTypes.any.isRequired,
	data: PropTypes.object.isRequired,
	isAdmin: PropTypes.bool.isRequired,
};

export default StatusUpdateModal;
