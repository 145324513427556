import Icon from '@ant-design/icons';
import PropTypes from 'prop-types';

const component = ({ active = false }) => (
	<svg
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<g clipPath='url(#clip0_911_454)'>
			<path
				d='M19 5V7H15V5H19ZM9 5V11H5V5H9ZM19 13V19H15V13H19ZM9 17V19H5V17H9ZM21 3H13V9H21V3ZM11 3H3V13H11V3ZM21 11H13V21H21V11ZM11 15H3V21H11V15Z'
				fill={active ? '#042954' : '#808080'}
			/>
		</g>
		<defs>
			<clipPath id='clip0_911_454'>
				<rect width='24' height='24' fill='white' />
			</clipPath>
		</defs>
	</svg>
);

function CustomDashboardIcon(props) {
	const { active, style } = props;
	return (
		<Icon
			{...{
				style,
				component: () => component({ active }),
			}}
		/>
	);
}

CustomDashboardIcon.defaultProps = {
	active: false,
	style: {},
};

CustomDashboardIcon.propTypes = {
	active: PropTypes.bool,
	style: PropTypes.object,
};

export default CustomDashboardIcon;
