import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
	Button,
	Row,
	Col,
	Divider,
	Form,
	Input,
	InputNumber,
	Select,
	Radio,
	Space,
	PageHeader,
	Upload,
	message,
	Typography,
} from 'antd';
import ImgCrop from 'antd-img-crop';
import { InboxOutlined } from '@ant-design/icons';
import useAxiosPrivate from 'hooks/use-axios-private';
import { customRequest, onRemove } from 'utils/firebase/storage';
import { AssignUsersModal, PoxLoader, Map } from 'components';
import { useSelector } from 'react-redux';
import { getUser } from 'redux/features/auth';
import { reverseGeocoding } from 'apis/openCage';
import { onlyNumbersRule, requiredRule } from 'utils/formRules';

const initialValues = {
	ownership: false,
};

export default function NewPox() {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const user = useSelector(getUser);
	const axiosPrivate = useAxiosPrivate();
	const [isCreating, setIsCreating] = useState(false);
	const [poxType, setPoxType] = useState(null);
	const [payType, setPayType] = useState(null);
	const [position, setPosition] = useState({
		lat: 20.5937,
		lng: 78.9629,
	});
	const [selectedUser, setSelectedUser] = useState(null);
	const [isOpen, setIsOpen] = useState(false);
	const [isGenerating, setIsGenerating] = useState(false);
	const [areaUnit, setAreaUnit] = useState('SQMS');
	const [countryCode, setCountryCode] = useState('+91');
	const [currency, setCurrency] = useState('INR');

	// const normFile = (e) => {
	// 	const list = e?.fileList.map((f) => {
	// 		if (f.response) {
	// 			return {
	// 				uid: f.uid,
	// 				name: f.name,
	// 				size: f.size,
	// 				status: f.status,
	// 				pathname: f.response.pathname,
	// 				url: f.response.url,
	// 			};
	// 		}
	// 		return f;
	// 	});
	// 	return list;
	// };

	const onChangeUpload = async ({ fileList }) => {
		if (fileList.length === 0) {
			form.setFieldValue('images', []);
		} else {
			const list = fileList.map((f) => {
				if (f.response) {
					return {
						uid: f.uid,
						name: f.name,
						size: f.size,
						status: f.status,
						pathname: f.response.pathname,
						url: f.response.url,
					};
				}
				return f;
			});
			form.setFieldValue('images', list);
		}
	};

	const handleCancel = () => {
		// eslint-disable-next-line no-alert
		const res = window.confirm(
			'Are you sure you want to leave this page? Changes would be lost'
		);
		if (res) navigate(-1);
	};

	const handleOnFinish = async (values) => {
		let stillUploading = false;
		let failedUpload = false;

		values.images?.forEach((e) => {
			if (e.status === 'uploading') stillUploading = true;
			if (e.status === 'error') failedUpload = true;
		});

		if (stillUploading)
			return message.error(
				'Please wait for pending uploads to complete or cancel and retry if taking too long'
			);

		if (failedUpload)
			return message.error(
				'Please remove failed image upload and try again'
			);

		const bodyData = {
			...values,
			position,
			area_unit: areaUnit,
			contact_number_cc: countryCode,
			currency,
		};
		if (user.isAdmin) {
			if (selectedUser) {
				bodyData.publishedBy = { uid: selectedUser.uid };
			} else {
				return message.warn(
					'Please assign pox to a user before proceeding'
				);
			}
		}

		setIsCreating(true);
		try {
			const res = await axiosPrivate.post(`/poxes`, bodyData);
			message.success(res.data.message);
			navigate('/poxes', { replace: true });
		} catch (error) {
			message.error(error?.response?.data.message || error.message);
		} finally {
			setIsCreating(false);
		}

		return undefined;
	};

	const generateAddress = async () => {
		setIsGenerating(true);
		const res = await reverseGeocoding(position);
		form.setFieldsValue({ address: res?.formatted });
		setIsGenerating(false);
	};

	const SelectAreaUnit = (
		<Select defaultValue='SQFTS' onChange={(value) => setAreaUnit(value)}>
			<Select.Option value='SQFTS'>SQFTS</Select.Option>
			<Select.Option value='SQMS'>SQMS</Select.Option>
			<Select.Option value='CENTS'>CENTS</Select.Option>
			<Select.Option value='ACRES'>ACRES</Select.Option>
		</Select>
	);

	const CountryCodes = (
		<Select defaultValue='+91' onChange={(value) => setCountryCode(value)}>
			<Select.Option value='+91'>+91</Select.Option>
			<Select.Option value='+971'>+971</Select.Option>
		</Select>
	);

	const Currencies = (
		<Select defaultValue='INR' onChange={(value) => setCurrency(value)}>
			<Select.Option value='INR'>INR</Select.Option>
			<Select.Option value='AED'>AED</Select.Option>
		</Select>
	);

	return (
		<div style={{ height: '100%' }}>
			<PageHeader
				className='site-page-header'
				onBack={() => navigate(-1)}
				title='New Pox'
			/>

			<Form
				form={form}
				name='new_pox'
				onFinish={handleOnFinish}
				initialValues={initialValues}
				style={{ height: '100%' }}
			>
				<div style={{ padding: '0 2%' }}>
					<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
						<Col xs={24} md={12}>
							<div
								style={{
									backgroundColor: '#f2f3f8',
									padding: 16,
									marginBottom: 16,
								}}
							>
								<Form.Item
									name='images'
									valuePropName='fileList'
									// getValueFromEvent={normFile}
								>
									<ImgCrop quality={0.8} aspect={16 / 10}>
										<Upload.Dragger
											name='pox-img'
											customRequest={customRequest}
											onRemove={onRemove}
											onChange={onChangeUpload}
											multiple
										>
											<p className='ant-upload-drag-icon'>
												<InboxOutlined />
											</p>
											<p className='ant-upload-text'>
												Click or drag file to this area to upload
											</p>
											{/* <p className='ant-upload-hint'>
												Upload minimum of 3 images
											</p> */}
										</Upload.Dragger>
									</ImgCrop>
								</Form.Item>

								<Form.Item
									name='name'
									label='Property Name'
									rules={[requiredRule('Property name')]}
									style={{ marginTop: 16 }}
								>
									<Input placeholder='Property name' />
								</Form.Item>

								<Form.Item
									name='type'
									label='Property Type'
									rules={[requiredRule('Property Type')]}
									style={{ marginBottom: 0 }}
								>
									<Radio.Group
										onChange={(e) => {
											setPoxType(e.target.value);
											setPayType(null);
										}}
									>
										<Radio value='Flat'>FLAT</Radio>
										<Radio value='Plot'>PLOT</Radio>
										<Radio value='Estab'>ESTAB</Radio>
									</Radio.Group>
								</Form.Item>
							</div>

							{poxType !== null && (
								<div
									style={{
										backgroundColor: '#f2f3f8',
										padding: 16,
										marginBottom: 16,
									}}
								>
									{poxType === 'Flat' && (
										<>
											<Form.Item
												name='room_config'
												label='Room Config'
												rules={[requiredRule('Room Config')]}
											>
												<Select>
													<Select.Option value='1RK'>
														1RK
													</Select.Option>
													<Select.Option value='1BHK'>
														1BHK
													</Select.Option>
													<Select.Option value='2BHK'>
														2BHK
													</Select.Option>
													<Select.Option value='3BHK'>
														3BHK
													</Select.Option>
												</Select>
											</Form.Item>

											<Form.Item
												name='bath'
												label='Bath'
												rules={[requiredRule('Number of Bath')]}
											>
												<Select>
													<Select.Option value='1'>
														1
													</Select.Option>
													<Select.Option value='2'>
														2
													</Select.Option>
													<Select.Option value='3'>
														3
													</Select.Option>
													<Select.Option value='4'>
														4
													</Select.Option>
													<Select.Option value='5'>
														5
													</Select.Option>
												</Select>
											</Form.Item>

											<Form.Item
												name='floor'
												label='Floor'
												rules={[requiredRule('Floor')]}
											>
												<Radio.Group>
													<Radio value='Ground'>Ground</Radio>
													<Radio value='First'>First</Radio>
													<Radio value='Middle'>Middle</Radio>
													<Radio value='Top'>Top</Radio>
												</Radio.Group>
											</Form.Item>

											<Form.Item
												name='facing'
												label='Entrance Face Direction'
											>
												<Select>
													<Select.Option value='North'>
														North
													</Select.Option>
													<Select.Option value='East'>
														East
													</Select.Option>
													<Select.Option value='West'>
														West
													</Select.Option>
													<Select.Option value='South'>
														South
													</Select.Option>
													<Select.Option value='North-East'>
														North-East
													</Select.Option>
													<Select.Option value='North-West'>
														North-West
													</Select.Option>
													<Select.Option value='South-East'>
														South-East
													</Select.Option>
													<Select.Option value='South-West'>
														South-West
													</Select.Option>
												</Select>
											</Form.Item>
										</>
									)}
									{poxType === 'Plot' && (
										<Form.Item
											name='plot_config'
											label='Plot Type'
											rules={[requiredRule('Plot Type')]}
										>
											<Radio.Group>
												<Radio value='Residential'>
													Residential
												</Radio>
												<Radio value='Commercial'>Commercial</Radio>
												<Radio value='Agricultural'>
													Agricultural
												</Radio>
											</Radio.Group>
										</Form.Item>
									)}
									<Form.Item
										name='area'
										label={
											poxType === 'Estab'
												? 'Floor Area'
												: poxType === 'Flat'
												? 'Built-Up Area'
												: 'Plot Area'
										}
										rules={[
											requiredRule(
												poxType === 'Estab'
													? 'Floor Area'
													: poxType === 'Flat'
													? 'Built-Up Area'
													: 'Plot Area'
											),
										]}
									>
										<Input
											placeholder='1000'
											addonAfter={SelectAreaUnit}
										/>
									</Form.Item>

									<Form.Item
										name='pay_type'
										label='Payment Type'
										rules={[requiredRule('Payment Type')]}
										style={{ marginBottom: 0 }}
									>
										<Radio.Group
											onChange={(e) => setPayType(e.target.value)}
										>
											{poxType !== 'Plot' && (
												<Radio value='Rent'>RENT</Radio>
											)}
											<Radio value='Sale'>SALE</Radio>
											{poxType === 'Plot' && (
												<Radio value='Lease'>LEASE</Radio>
											)}
										</Radio.Group>
									</Form.Item>
								</div>
							)}

							{poxType !== null && payType !== null && (
								<div
									style={{
										backgroundColor: '#f2f3f8',
										padding: 16,
										marginBottom: 16,
									}}
								>
									{payType === 'Sale' && (
										<Form.Item
											name='sale_cost'
											label='Sale Cost'
											rules={[
												requiredRule('Sale Cost'),
												onlyNumbersRule(),
											]}
											style={
												poxType !== 'Estab'
													? { marginBottom: 0 }
													: {}
											}
										>
											<Input
												addonBefore={Currencies}
												placeholder='1000'
											/>
										</Form.Item>
									)}
									{payType === 'Rent' && (
										<>
											<Form.Item
												name='rent_cost'
												label='Rent'
												rules={[
													requiredRule('Rent Amount'),
													onlyNumbersRule(),
												]}
											>
												<Input
													addonBefore={Currencies}
													placeholder='1000'
												/>
											</Form.Item>
											<Form.Item
												name='deposit_cost'
												label='Deposit'
												rules={[
													requiredRule('Deposit Amount'),
													onlyNumbersRule(),
												]}
												style={
													poxType !== 'Estab'
														? {
																marginBottom: 0,
														  }
														: {}
												}
											>
												<Input
													addonBefore={Currencies}
													placeholder='1000'
												/>
											</Form.Item>
										</>
									)}
									{poxType === 'Estab' && payType !== null && (
										<Form.Item
											name='turnover_cost'
											label='Turnover'
											rules={[
												requiredRule('Turnover'),
												onlyNumbersRule(),
											]}
											style={{ marginBottom: 0 }}
										>
											<Input
												addonBefore={Currencies}
												placeholder='1000'
											/>
										</Form.Item>
									)}
									{poxType === 'Plot' && payType === 'Lease' && (
										<>
											<Form.Item
												name='lease_cost'
												label='Lease Cost'
												rules={[
													requiredRule('Lease Cost'),
													onlyNumbersRule(),
												]}
											>
												<Input
													addonBefore={Currencies}
													placeholder='1000'
												/>
											</Form.Item>
											<Form.Item
												name='lease_period'
												label='Lease Duration (days)'
												rules={[
													requiredRule('Lease Duration'),
													onlyNumbersRule(),
												]}
												style={{ marginBottom: 0 }}
											>
												<InputNumber
													addonBefore={Currencies}
													min={0}
												/>
											</Form.Item>
										</>
									)}
								</div>
							)}

							<div
								style={{
									backgroundColor: '#f2f3f8',
									padding: 16,
									marginBottom: 16,
								}}
							>
								<Form.Item
									name='contact_name'
									label='Contact Name'
									rules={[requiredRule('Contact Name')]}
								>
									<Input />
								</Form.Item>
								<Form.Item
									name='contact_number'
									label='Contact Number'
									rules={[
										requiredRule('Contact Number'),
										onlyNumbersRule(),
									]}
									style={{ marginBottom: 0 }}
								>
									<Input
										addonBefore={CountryCodes}
										placeholder='Include trailing 0, no spaces'
									/>
								</Form.Item>
							</div>

							<div style={{ backgroundColor: '#f2f3f8', padding: 16 }}>
								<Form.Item name='ownership' label='Property Owned by'>
									<Radio.Group>
										<Radio value>Self</Radio>
										<Radio value={false}>Others</Radio>
									</Radio.Group>
								</Form.Item>
							</div>
						</Col>

						<Col xs={24} md={12}>
							<Divider orientation='left'>
								<Typography.Text strong>Pick Location</Typography.Text>
							</Divider>

							{/* map select area */}
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<div
									style={{
										width: '96%',
										height: '350px',
										position: 'absolute',
										zIndex: 1,
									}}
								>
									<Map position={position} setPosition={setPosition} />
								</div>
							</div>

							{/* more info */}
							<div
								style={{
									position: 'absolute',
									top: '430px',
									width: '96%',
								}}
							>
								<div
									style={{
										display: 'flex',
										justifyContent: 'flex-end',
									}}
								>
									<Button
										type='link'
										style={{ fontSize: '0.9em' }}
										loading={isGenerating}
										onClick={generateAddress}
									>
										Auto Generate Address
									</Button>
								</div>
								<Form.Item
									name='address'
									label='Address'
									rules={[requiredRule('Address')]}
								>
									<Input disabled={isGenerating} />
								</Form.Item>

								<Form.Item name='description'>
									<Input.TextArea
										placeholder='Description...'
										showCount
										maxLength={500}
										autoSize={{ minRows: 3, maxRows: 5 }}
									/>
								</Form.Item>

								{user.isAdmin &&
									(selectedUser ? (
										<div>
											<Space style={{ marginBottom: 6 }}>
												<Typography.Text>
													Assigned to:
												</Typography.Text>
												<Link to={`/users/${selectedUser.uid}`}>
													{selectedUser.uid}
												</Link>
											</Space>

											<Button
												size='small'
												type='primary'
												onClick={() => setIsOpen(true)}
											>
												Re-assign
											</Button>
										</div>
									) : (
										<Button
											type='primary'
											aria-required='true'
											onClick={() => setIsOpen(true)}
										>
											Assign to
										</Button>
									))}

								<div className='btns-group'>
									<Space size='large'>
										<Button
											danger
											shape='round'
											htmlType='button'
											onClick={handleCancel}
											style={{ width: 125 }}
										>
											Cancel
										</Button>
										<Button
											type='primary'
											shape='round'
											htmlType='submit'
											style={{ width: 125 }}
										>
											Submit
										</Button>
									</Space>
								</div>
							</div>
						</Col>
					</Row>
				</div>
			</Form>

			{isCreating && <PoxLoader title='Creating your Pox, please wait...' />}

			{user.isAdmin && (
				<AssignUsersModal
					visible={isOpen}
					closeModal={() => setIsOpen(false)}
					user={selectedUser}
					setUser={setSelectedUser}
				/>
			)}
		</div>
	);
}
