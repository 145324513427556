import Icon from '@ant-design/icons';
import PropTypes from 'prop-types';

const component = () => (
	<svg
		width='14'
		height='14'
		viewBox='0 0 20 20'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M4.42708 20H18.9062C19.2188 20 19.4792 19.9219 19.6875 19.7656C19.8958 19.6094 20 19.4141 20 19.1797V1.67969C20 1.21094 19.783 0.813802 19.349 0.488281C18.9149 0.16276 18.3854 0 17.7604 0H2.23958C1.61458 0 1.08507 0.16276 0.651042 0.488281C0.217014 0.813802 0 1.21094 0 1.67969V19.1797C0 19.4141 0.104167 19.6094 0.3125 19.7656C0.520833 19.9219 0.78125 20 1.09375 20H4.42708ZM8.90625 18.3203H6.66667V15H8.90625V18.3203ZM13.3333 18.3203H11.0938V15H13.3333V18.3203ZM2.23958 1.67969H17.7604V18.3203H15.5729V15C15.5729 14.5573 15.3472 14.1667 14.8958 13.8281C14.4444 13.4896 13.9236 13.3203 13.3333 13.3203H6.66667C6.07639 13.3203 5.55556 13.4896 5.10417 13.8281C4.65278 14.1667 4.42708 14.5573 4.42708 15V18.3203H2.23958V1.67969ZM4.42708 3.32031H6.66667V5H4.42708V3.32031ZM8.90625 3.32031H11.0938V5H8.90625V3.32031ZM13.3333 3.32031H15.5729V5H13.3333V3.32031ZM4.42708 6.67969H6.66667V8.32031H4.42708V6.67969ZM8.90625 6.67969H11.0938V8.32031H8.90625V6.67969ZM13.3333 6.67969H15.5729V8.32031H13.3333V6.67969ZM4.42708 10H6.66667V11.6797H4.42708V10ZM8.90625 10H11.0938V11.6797H8.90625V10ZM13.3333 10H15.5729V11.6797H13.3333V10Z'
			fill='#0b4e9b'
		/>
	</svg>
);

function EstabIcon(props) {
	const { style } = props;
	return (
		<Icon
			{...{
				style,
				component: () => component(),
			}}
		/>
	);
}

EstabIcon.defaultProps = {
	style: {},
};

EstabIcon.propTypes = {
	style: PropTypes.object,
};

export default EstabIcon;
