/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	user: null,
	token: null,
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		logInSuccess: (state, action) => {
			state.user = action.payload.user;
			state.token = action.payload.token;
		},
		logOutSuccess: (state) => {
			state.user = null;
			state.token = null;
		},
		updateUserProfile: (state, action) => {
			state.user = action.payload;
		},
	},
});

export const {
	logInSuccess,
	logOutSuccess,
	persistLogInSuccess,
	updateUserProfile,
} = authSlice.actions;

export const getUser = (state) => state.auth.user;

export default authSlice.reducer;
